import React from 'react';

const Logo = () => (
    <svg className="logo" height="142.61" viewBox="0 0 240.11 142.61" width="240.11" xmlns="http://www.w3.org/2000/svg">
        <g fill="#37af4a">
            <path fill="#fff" d="m230.09969 48.0489-23.27005-39.01155a9.36277 9.36277 0 0 0 -8.31334-5.06466h-177.13527a9.35365 9.35365 0 0 0 -9.38102 9.3354v78.09611a9.35365 9.35365 0 0 0 9.38102 9.29889h177.12615a9.35365 9.35365 0 0 0 8.31334-5.06466l23.27918-38.9933a9.39928 9.39928 0 0 0 0-8.59623z"></path>
            <path d="m239 48.3-25.5-42.75a10.26 10.26 0 0 0 -9.11-5.55h-194.11a10.25 10.25 0 0 0 -10.28 10.23v85.58a10.25 10.25 0 0 0 10.28 10.19h194.1a10.25 10.25 0 0 0 9.11-5.55l25.51-42.73a10.3 10.3 0 0 0 0-9.42zm-180.54-5-19.68 40.28a1 1 0 0 1 -1.85 0l-19.76-40.27a.6.6 0 0 1 .62-.87h7.58c.26 0 .51 0 .62.27l11.87 25.66 11.79-25.66a.6.6 0 0 1 .62-.3h7.57a.59.59 0 0 1 .59.85zm41.37 18.7-.06 1.76a.65.65 0 0 1 -.69.59h-30.9a14.25 14.25 0 0 0 3.43 9.9 12.18 12.18 0 0 0 9.5 4.13c4.91 0 9.69-3.19 12.16-7.38a.68.68 0 0 1 .93-.24l5.11 3a.69.69 0 0 1 .25.94q-6.86 10.7-19.36 10.71a18.43 18.43 0 0 1 -14.77-6.62q-5.49-6.44-5.49-17.28a22.08 22.08 0 0 1 1.66-8.06 18.51 18.51 0 0 1 10.23-10.8 21.24 21.24 0 0 1 8.35-1.65q13.13 0 17.69 11.06a23.7 23.7 0 0 1 1.96 9.94zm43.17 21.28c0 .38-.58.86-1 .86h-6.67c-.38 0-.6-.48-.6-.86v-24.53c0-3.58-.67-6.18-1.92-7.8-1.42-1.88-4-2.82-7.45-2.82-6.89 0-10.47 4.34-10.47 13v22.18c0 .37-.06.85-.44.85h-6.61c-.38 0-.47-.48-.47-.85v-40.49c0-.38.09-.41.47-.41h6.65c.37 0 .44 0 .44.41v5.28c3.42-4.73 7.85-7.11 13.4-7.11 9.73.01 14.67 6.01 14.67 17.89zm49.26 0c0 .38-.31.86-.69.86h-6.65a1.05 1.05 0 0 1 -.88-.86v-6.1c-3.42 5.49-8.29 8.23-14.19 8.23-6.56 0-11.64-2.46-15.32-7.36a23.63 23.63 0 0 1 -4.86-14.58 26.28 26.28 0 0 1 2.45-11.41 18.78 18.78 0 0 1 7.81-8.49 19.42 19.42 0 0 1 9.64-2.57 16.8 16.8 0 0 1 8.4 2.32 15 15 0 0 1 6 6.1v-28.32c0-.38.51-.58.88-.58h6.65c.38 0 .69.2.69.58zm25.65-25.13a5.14 5.14 0 1 1 5.09-5.15 5.14 5.14 0 0 1 -5.12 5.15z"></path>
            <path d="m177 50.45a13.17 13.17 0 0 0 -6.95-1.85q-8 0-11.28 7.87a18.22 18.22 0 0 0 -1.38 7 14.8 14.8 0 0 0 5.17 11.87 10.87 10.87 0 0 0 7.32 2.44q8.66 0 12-7.53a17 17 0 0 0 1.43-7q-.05-9-6.31-12.8z"></path>
            <path d="m80.74 47.94a12 12 0 0 0 -8.14 2.7 13.59 13.59 0 0 0 -4.09 7.52h23.39a13.23 13.23 0 0 0 -3.53-7.33 10.35 10.35 0 0 0 -7.63-2.89z"></path>
        </g>

        <g fill="#fff">
            <path d="m15.46 123.14a5.83 5.83 0 0 1 2.18-2.14 6 6 0 0 1 2.93-.73 7 7 0 0 1 3 .61 6.33 6.33 0 0 1 2.26 1.74 7.76 7.76 0 0 1 1.43 2.69 11.89 11.89 0 0 1 0 6.79 8.4 8.4 0 0 1 -1.41 2.72 6.92 6.92 0 0 1 -2.27 1.82 6.48 6.48 0 0 1 -2.9.66 6.53 6.53 0 0 1 -3.3-.77 5.21 5.21 0 0 1 -1.91-2v2.47h-2.59v-22h2.58zm4.71-.66a4.26 4.26 0 0 0 -3.46 1.63 7.4 7.4 0 0 0 -1.34 4.8 9.13 9.13 0 0 0 .37 2.75 5.78 5.78 0 0 0 1 2 4.08 4.08 0 0 0 1.53 1.13 4.82 4.82 0 0 0 1.88.38 4.31 4.31 0 0 0 3.57-1.7 7.08 7.08 0 0 0 1.36-4.58 7.83 7.83 0 0 0 -1.24-4.66 4.26 4.26 0 0 0 -3.7-1.7z"></path>
            <path d="m38.24 137.86a18.35 18.35 0 0 1 -.76 1.9 5.05 5.05 0 0 1 -1 1.48 4.41 4.41 0 0 1 -1.53 1 6 6 0 0 1 -2.18.35 7.33 7.33 0 0 1 -1.81-.2v-2a4 4 0 0 0 .69 0h.65a4.31 4.31 0 0 0 1.51-.21 2.59 2.59 0 0 0 1-.65 3.27 3.27 0 0 0 .65-1.13c.17-.43.34-.94.51-1.5h-.85l-5.78-16.35h2.79l4.74 14.34 4.3-14.34h2.63z"></path>
            <path d="m56.69 134.59h10.18v2.41h-12.79v-22h2.61z"></path>
            <path d="m71.12 114.57a1.69 1.69 0 0 1 1.2.49 1.57 1.57 0 0 1 .49 1.13 1.64 1.64 0 0 1 -.49 1.21 1.69 1.69 0 0 1 -1.2.49 1.72 1.72 0 0 1 -1.22-.49 1.61 1.61 0 0 1 -.5-1.21 1.54 1.54 0 0 1 .5-1.13 1.67 1.67 0 0 1 1.22-.49zm1.29 22.43h-2.57v-16.37h2.57z"></path>
            <path d="m91 121.85a6.57 6.57 0 0 0 -1.53.15 3 3 0 0 0 -1.13.52 4.25 4.25 0 0 1 .8 1.46 5 5 0 0 1 .28 1.67 4.82 4.82 0 0 1 -.48 2.14 4.71 4.71 0 0 1 -1.33 1.61 6.3 6.3 0 0 1 -2.09 1 9.71 9.71 0 0 1 -2.73.36 8.22 8.22 0 0 1 -1.72-.15 8.87 8.87 0 0 1 -1.66-.52 3.09 3.09 0 0 0 -.52.67 1.61 1.61 0 0 0 -.2.71 1.45 1.45 0 0 0 .52 1.22 2.78 2.78 0 0 0 1.71.41h3.39a10.74 10.74 0 0 1 3 .36 5.83 5.83 0 0 1 2 1 3.7 3.7 0 0 1 1.13 1.46 4.73 4.73 0 0 1 .34 1.78 4 4 0 0 1 -2 3.53 10 10 0 0 1 -5.6 1.34 15 15 0 0 1 -3.47-.35 7.13 7.13 0 0 1 -2.26-.94 3.54 3.54 0 0 1 -1.27-1.39 3.72 3.72 0 0 1 1.65-5 4.4 4.4 0 0 1 .5-.21 2.81 2.81 0 0 1 -1.83-2.68 3.44 3.44 0 0 1 1.67-2.67 4.87 4.87 0 0 1 -1.38-1.58 4.44 4.44 0 0 1 -.5-2.12 5 5 0 0 1 .41-2 4.6 4.6 0 0 1 1.24-1.72 6 6 0 0 1 2-1.13 8.69 8.69 0 0 1 2.86-.45 9.8 9.8 0 0 1 2.35.27 7.59 7.59 0 0 1 1.85.73 4.77 4.77 0 0 1 4.08-1.72zm-11 13.33a4.41 4.41 0 0 0 -1.56 1.25 2.48 2.48 0 0 0 -.4 1.35 2.24 2.24 0 0 0 1.13 2 7.48 7.48 0 0 0 3.95.75 7.69 7.69 0 0 0 3.8-.75 2.26 2.26 0 0 0 1.28-2 2.71 2.71 0 0 0 -.22-1.13 1.78 1.78 0 0 0 -.76-.82 4 4 0 0 0 -1.42-.49 14.35 14.35 0 0 0 -2.27-.15zm2.8-13a4.44 4.44 0 0 0 -3.08.94 3.1 3.1 0 0 0 -1 2.41 3 3 0 0 0 .99 2.47 4.49 4.49 0 0 0 3 .88 4.83 4.83 0 0 0 3.08-.87 3 3 0 0 0 1.06-2.43 3.12 3.12 0 0 0 -1-2.43 4.44 4.44 0 0 0 -3-.88z"></path>
            <path d="m96.33 123.14a6 6 0 0 1 2.12-2.1 5.86 5.86 0 0 1 2.92-.75 5.22 5.22 0 0 1 4.2 1.66 6.89 6.89 0 0 1 1.43 4.61v10.44h-2.58v-9.79a11.12 11.12 0 0 0 -.17-1.91 4.11 4.11 0 0 0 -.58-1.5 3 3 0 0 0 -1.13-1 4 4 0 0 0 -1.73-.34 4.2 4.2 0 0 0 -3.18 1.44 5.35 5.35 0 0 0 -1 1.7 6.65 6.65 0 0 0 -.36 2.26v9.05h-2.56v-21.91h2.58z"></path>
            <path d="m114.43 120.63h4.57v2h-4.58v10a3 3 0 0 0 .51 1.87 2.27 2.27 0 0 0 1.91.67 7.5 7.5 0 0 0 1.42-.13 10.26 10.26 0 0 0 1.22-.28v2.07a8.92 8.92 0 0 1 -1.44.36 9.71 9.71 0 0 1 -1.71.15 5.88 5.88 0 0 1 -2.12-.33 3.72 3.72 0 0 1 -1.39-.92 3.16 3.16 0 0 1 -.75-1.38 6.39 6.39 0 0 1 -.22-1.74v-10.36h-2.33v-2h2.33v-4.36h2.57z"></path>
            <path d="m128.3 120.29a9.92 9.92 0 0 1 3.74.64 8.54 8.54 0 0 1 2.7 1.73l-1.54 1.54a7 7 0 0 0 -2.16-1.31 7.32 7.32 0 0 0 -2.74-.47 4.82 4.82 0 0 0 -2.75.66 2 2 0 0 0 -1 1.69 1.92 1.92 0 0 0 .22 1 2 2 0 0 0 .73.73 5.19 5.19 0 0 0 1.37.53c.71.17 1.42.31 2.14.41a18.68 18.68 0 0 1 2.64.61 6.57 6.57 0 0 1 1.91.95 3.58 3.58 0 0 1 1.13 1.4 4.87 4.87 0 0 1 .4 2 4.18 4.18 0 0 1 -1.77 3.51 6.43 6.43 0 0 1 -2.16 1 10.07 10.07 0 0 1 -3 .39 11.41 11.41 0 0 1 -4.2-.74 8.4 8.4 0 0 1 -3.07-2.06l1.66-1.63a6.68 6.68 0 0 0 2.43 1.68 8.39 8.39 0 0 0 3.13.58 5.8 5.8 0 0 0 3.28-.75 2.28 2.28 0 0 0 1.05-1.91 2.6 2.6 0 0 0 -.2-1.06 2 2 0 0 0 -.76-.8 6.14 6.14 0 0 0 -1.48-.61 19.41 19.41 0 0 0 -2.41-.49 8.73 8.73 0 0 1 -4.3-1.61 3.81 3.81 0 0 1 -1.35-3 4 4 0 0 1 .46-1.91 4.28 4.28 0 0 1 1.27-1.44 5.78 5.78 0 0 1 2-.92 8.86 8.86 0 0 1 2.63-.34z"></path>
            <path d="m146.13 120.29a6.69 6.69 0 0 1 3 .62 6.42 6.42 0 0 1 2.26 1.74 8.26 8.26 0 0 1 1.41 2.67 10.61 10.61 0 0 1 .5 3.4 11.75 11.75 0 0 1 -.46 3.39 7.9 7.9 0 0 1 -1.41 2.75 6.69 6.69 0 0 1 -2.26 1.83 6.41 6.41 0 0 1 -3 .68 6.27 6.27 0 0 1 -3.12-.73 5.27 5.27 0 0 1 -2-2v7.44h-2.54v-21.45h2.49v2.45a6 6 0 0 1 5.19-2.79zm-.4 2.19a4.34 4.34 0 0 0 -3.51 1.62 7.29 7.29 0 0 0 -1.34 4.72 9 9 0 0 0 .39 2.83 5.65 5.65 0 0 0 1 2 4.08 4.08 0 0 0 1.5 1.13 4.81 4.81 0 0 0 1.87.38 4.3 4.3 0 0 0 3.57-1.7 7.36 7.36 0 0 0 1.35-4.74 9.71 9.71 0 0 0 -.3-2.52 6 6 0 0 0 -.93-2 4.28 4.28 0 0 0 -1.53-1.27 4.57 4.57 0 0 0 -2.07-.45z"></path>
            <path d="m163.39 120.29a6.6 6.6 0 0 1 5.38 2.49 7.91 7.91 0 0 1 1.4 2.7 11.07 11.07 0 0 1 .47 3.28v.59h-12.17a6.43 6.43 0 0 0 1.4 4.29 4.86 4.86 0 0 0 3.81 1.54 5.3 5.3 0 0 0 2.81-.71 5.7 5.7 0 0 0 1.83-1.89l2.14 1.13a7.93 7.93 0 0 1 -2.81 2.68 8.34 8.34 0 0 1 -4.07 1 7.74 7.74 0 0 1 -3.39-.68 7.08 7.08 0 0 1 -2.45-1.82 7.47 7.47 0 0 1 -1.49-2.72 10.51 10.51 0 0 1 -.5-3.39 10.81 10.81 0 0 1 .49-3.34 7.78 7.78 0 0 1 1.42-2.7 6.61 6.61 0 0 1 2.36-1.8 7.62 7.62 0 0 1 3.37-.65zm-.07 2.13a4.4 4.4 0 0 0 -3.39 1.36 5.82 5.82 0 0 0 -1.41 3.59h9.48a6 6 0 0 0 -1.34-3.6 4.12 4.12 0 0 0 -3.35-1.35z"></path>
            <path d="m180.71 120.29a6.63 6.63 0 0 1 5.39 2.49 7.88 7.88 0 0 1 1.39 2.7 11.07 11.07 0 0 1 .47 3.28v.59h-12.17a6.53 6.53 0 0 0 1.4 4.29 4.87 4.87 0 0 0 3.84 1.52 5.19 5.19 0 0 0 2.8-.72 5.46 5.46 0 0 0 1.84-1.89l2.14 1.13a8 8 0 0 1 -2.81 2.69 8.36 8.36 0 0 1 -4.07 1 7.81 7.81 0 0 1 -3.4-.68 7 7 0 0 1 -2.44-1.82 7.73 7.73 0 0 1 -1.5-2.72 10.83 10.83 0 0 1 -.49-3.39 11.11 11.11 0 0 1 .48-3.34 7.63 7.63 0 0 1 1.43-2.7 6.58 6.58 0 0 1 2.35-1.8 7.57 7.57 0 0 1 3.35-.63zm-.06 2.13a4.41 4.41 0 0 0 -3.39 1.36 5.81 5.81 0 0 0 -1.4 3.59h9.48a5.88 5.88 0 0 0 -1.34-3.6 4.12 4.12 0 0 0 -3.35-1.35z"></path>
            <path d="m205.32 137h-2.58v-2.51a5.71 5.71 0 0 1 -2.18 2.1 6 6 0 0 1 -3 .76 6.88 6.88 0 0 1 -3-.64 6.3 6.3 0 0 1 -2.26-1.74 7.88 7.88 0 0 1 -1.39-2.66 11.89 11.89 0 0 1 0-6.79 8.55 8.55 0 0 1 1.43-2.72 6.29 6.29 0 0 1 2.26-1.8 6.9 6.9 0 0 1 3-.65 6 6 0 0 1 3.1.74 5.52 5.52 0 0 1 2 1.92v-7.92h2.58zm-7.32-14.52a4.27 4.27 0 0 0 -3.62 1.7 7.51 7.51 0 0 0 -1.27 4.64 7.74 7.74 0 0 0 1.21 4.63 4.19 4.19 0 0 0 3.65 1.68 4.37 4.37 0 0 0 3.49-1.6 7.2 7.2 0 0 0 1.35-4.77 8.83 8.83 0 0 0 -.38-2.75 5.78 5.78 0 0 0 -1-2 4.18 4.18 0 0 0 -1.52-1.14 4.55 4.55 0 0 0 -1.91-.39z"></path>
        </g>
    </svg>
);

export default Logo;
