export const validateName = (value, field) => {
    if (!field.validation.name) {
        return true;
    }

    return /^[^/:_<>]+$/.test(value) || field.validation.name;
};

export const validateEmail = (value, field) => {
    if (!field.validation.email) {
        return true;
    }

    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value) || field.validation.email;
};

export const validateCountry = (value, field, countries) => {
    if (!field.validation.country) {
        return true;
    }

    return (countries !== null && countries?.filter(country => country.code === value)[0]?.supported) || field.validation.country;
};

export const validateDomain = async (value, field, setError) => {
    if (!field.validation.domain) {
        return true;
    }

    try {
        const response = await fetch('/actions/signup/check-domain-prefix', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prefix: value,
                [window.vhqSiteConfig.csrfTokenName]: window.vhqSiteConfig.csrfToken,
            }),
        });
        const result = await response.json();

        if (!result.valid || !result.available) {
            return field.validation.domain;
        }

        return true;
    } catch (e) {
        const message = `
            Sorry about this, but we’re having trouble validating your Vend URL.
            This might just be temporary - try again in a moment.
            If it keeps happening, please contact us for assistance.
        `;

        if (window.env === 'dev') {
            // Render a standard validation error message in dev to allow debugging
            return 'Failed to validate';
        }

        setError({
            message,
            errorDescription: e.message,
        });

        // send error to Raygun
        if (window.rg4js) {
            window.rg4js('send', {
                error: new Error(`Failed to validate Vend URL on signup: ${e.message}`),
                customData: {
                    prefix: field.value,
                },
            });
        }
    }

    return false;
};
