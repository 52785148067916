import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { show, setSteps, setFormOptions, setModalMode, setDismissable } from '../../../components/organisms/signupApp/store/signupSlice';

import Logo from './components/Logo.jsx';
import Video from './components/Video.jsx';

const DemoVideoSignupApp = () => {
    const [initialised, setInitialised] = useState(false);
    const [videoClosed, setVideoClosed] = useState(true);
    const dispatch = useDispatch();

    const initialise = async () => {
        await dispatch(setFormOptions({
            ...window.demoVideoSignupOptions,
            salesforceLeadType: 'Demo Video Intent',
            gtmTriggerSubmitEventName: 'demo-signup-submit',
        }));

        await dispatch(setSteps([
            { key: 'step_1', step: 1, fieldGroup: 1, title: 'Get access to our full demo video', subtitle: 'Plus heaps more great online resources' },
            { key: 'step_2', step: 2, fieldGroup: 2, title: 'Get access to our full demo video', subtitle: 'Plus heaps more great online resources' },
            { key: 'step_3', step: 3, fieldGroup: 3, title: 'Get access to our full demo video', subtitle: 'Plus heaps more great online resources', buttonText: videoClosed ? 'Continue' : 'Watch Demo Video' },
            { key: 'step_4', step: 4, fieldGroup: 4, title: 'Almost there!', subtitle: 'Sign up now for your 14 day trial', buttonText: 'Start your trial' },
        ]));

        await dispatch(show());
        await dispatch(setModalMode());
        await dispatch(setDismissable(false))

        setInitialised(true);
    }

    const containerEl = document.getElementById('demo-signup-app');

    useEffect(() => {
        if (initialised || !containerEl) {
            return;
        }

        initialise();
    });

    if (!containerEl) {
        return null;
    }

    return (
        <>
            <Logo />
            <Video closed={videoClosed} setClosed={setVideoClosed} />
        </>
    );
}
export default DemoVideoSignupApp;