import './styles.scss';
import { customAlphabet as nanoidCustomAlphabet } from 'nanoid';

/* global $ */

/*
* Enables dropdown toggling for our generic drop down list component
*
* @param {Boolean} usedFromTopBar
*/
const dropdownSelector = function (usedFromTopBar) {
  // A check to ensure code does not run twice
  // Usually called from footer so makes sure topbar doesn't double up
  if (usedFromTopBar && document.getElementsByClassName('vhq-footer').length) {
    return;
  }

  $('.js-vhq-dropdown-selector-current').on('click', function () {
    // Toggle the target dropdown list
    var closestDropdownList = $(this).siblings('.vhq-dropdown-selector-list');
    closestDropdownList.toggleClass('vhq-dropdown-selector-open');
  })

  // Allows clicking outside dropdown to close it
  window.onclick = function (event) {
    if (
      !(event.target.matches('.js-vhq-dropdown-selector-current') ||
        event.target.matches('.js-vhq-dropdown-selector-current *'))
    ) {
      var dropdowns = document.getElementsByClassName(
        'js-vhq-dropdown-selector-list'
      );
      for (var i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('vhq-dropdown-selector-open')) {
          openDropdown.classList.remove('vhq-dropdown-selector-open')
        }
      }
    }
  }

  // Add cache busting suffix to ?_pcc URL parameters
  const nanoid = nanoidCustomAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 12);
  const cacheBuster = nanoid();

  $('.vhq-country-switcher .vhq-dropdown-selector-item a').each(function () {
    const href = $(this).attr('href');
    $(this).attr('href', href.replace(/(_pcc=[a-z]+)/, `$1-${cacheBuster}`))
  });

  // Send a tracking event when the country is changed
  $('.vhq-country-switcher').on('click', '.vhq-dropdown-selector-item a', function () {
    if ($(this).data('country')) {
      window.analytics.track('Country Switcher Used', {
        'country_initial': String($('.vhq-country-switcher').data('country')).toUpperCase(),
        'country_selected': String($(this).data('country')).toUpperCase()
      })
    }
  });
}

export default dropdownSelector
