import './styles.scss'
import '../signupApp/App.jsx';

/* global $ */

/*
 * A collection of functions for the navigation bar
 */
const navigation = {
  onLoad: () => {
    $('.vhq-hamburger-wrapper').click(function (e) {
      $('body').toggleClass('vhq-open-hamburger')
      $('.vhq-mobile-hamburger').toggleClass('open')
      e.preventDefault()
      $(document.body).toggleClass('vhq-no-scroll')
    })

    // Allows clicking outside dropdown to close it
    $(window).on('click', function (event) {
      if (
        !($(event.target).hasClass('vhq-menu-header') ||
          $(event.target).parents('.vhq-menu-header').length > 0)
      ) {
        $('.vhq-open-subnav').removeClass('vhq-open-subnav')
        $('.vhq-spin').removeClass('vhq-spin')
      }
    })

    $('.vhq-menu-link').on('click', function (e) {
      const currentSmallDropdown = $(this).siblings('.vhq-open-subnav')
      const currentSpinnerArrow = $(this).children('.vhq-chevron-wrapper')

      $('.vhq-open-subnav')
        .not(currentSmallDropdown)
        .removeClass('vhq-open-subnav')
      $('.vhq-chevron-wrapper').not(currentSpinnerArrow).removeClass('vhq-spin')
      $(this).next().toggleClass('vhq-open-subnav')
      $(this).children('.vhq-chevron-wrapper').toggleClass('vhq-spin')
    })
  },

  stickyNav: () => {
    const stickyClass = 'vhq-sticky'
    const header = $('.vhq-main-header')

    if (!header.length) {
      return;
    }

    let headerOffsetTop = header.offset().top

    $(window).on('scroll', handleStickyNav)
    handleStickyNav()

    function handleStickyNav () {
      if ($(window).scrollTop() > headerOffsetTop) {
        header.addClass(stickyClass)
      } else {
        header.removeClass(stickyClass)
      }
    }
  }
}

export default navigation
