import Cookies from "js-cookie";

export const handleCookies = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const domainChunks = window.location.host.split('.');
    const domain = `${domainChunks[domainChunks.length - 2]}.${domainChunks[domainChunks.length - 1]}`;

    // Set "partner_code" cookie from "partner_code" url param
    const partnerCode = searchParams.get('partner_code');

    if (partnerCode) {
        Cookies.set('partner_code', partnerCode, { expires: 91, domain });
    }

    // Set "campaign" cookie from "campaign" url param
    const campaign = searchParams.get('campaign');

    if (campaign) {
        Cookies.set('campaign', campaign, { expires: 91, domain });
    }

    // Set "trial_pricing_plan" cookie from "plan" url param
    const plan = searchParams.get('plan');

    if (plan) {
        Cookies.set('trial_pricing_plan', plan, { expires: 14, domain });
    }
}


export const initialiseData = async (initialisedData, fields, setCountries, setInitialisedData, setError, error, data, setData, setLoading, setLoadingText) => {
    if (initialisedData) {
        return;
    }

    setLoadingText('');
    setLoading(true);

    setTimezone(fields.filter(field => field.key === 'timezone')[0]);
    await setCountryAndCurrencyOptions(fields, setCountries, setInitialisedData, setError);

    setData({
        ...data,
        country: window.vhqSiteConfig.visitorCountry.isoCode.toUpperCase(),
    });
        
    setLoading(false);
    setInitialisedData(error === null);
}

export const setTimezone = (field) => {
    // Set timezone automatically using the browser timezone - supported by 90% of browsers, but let's be safe
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/resolvedOptions
    // https://caniuse.com/#feat=mdn-javascript_builtins_intl_datetimeformat_resolvedoptions_computed_timezone
    const canDetermineTimeZone =
        typeof Intl === 'object' &&
        typeof Intl.DateTimeFormat === 'function' &&
        typeof Intl.DateTimeFormat().resolvedOptions === 'function' &&
        typeof Intl.DateTimeFormat().resolvedOptions().timeZone === 'string' &&
        Intl.DateTimeFormat().resolvedOptions().timeZone !== '';

    if (canDetermineTimeZone) {
        field.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
        field.type = 'select';
    }
}

export const focusField = (control, changeDomain, formOptions) => {
    if (control.fieldsRef.current['password'] !== undefined && (window.demoVideoSignupOptions != undefined || window.multiVideoSignupOptions != undefined) && (window.innerWidth < 768 || window.innerHeight < 400)) {
        // We don't want to focus anything on the password step if video exists on small devices
        window.scrollTo(0, 0);
        return;
    }

    let fieldKey = Object.keys(control.fieldsRef.current)[0];

    if (!changeDomain && control.fieldsRef.current['password'] !== undefined) {
        fieldKey = 'password';
    }

    control.fieldsRef.current[fieldKey]?.ref.focus();

    if (window.innerWidth < 600) {
        // Always scroll to top on mob
        window.scrollTo(0, 0);
    }

    document.querySelector('.form-box').scrollTo(0, 0);
}

export const setCountryAndCurrencyOptions = async (fields, setCountries, setInitialisedData, setError) => {
    const countryField = fields.filter(field => field.key === 'country')[0];
    const currencyField = fields.filter(field => field.key === 'currency')[0];

    try {
        const response = await fetch('/actions/signup/countries-and-currencies', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const { countries, currencies } = await response.json();

        countries.forEach(country => {
            const group = country.popular ? 0 : 1;
            countryField.optionGroups[group].options.push({
                label: country.name,
                value: country.code,
                currencyCode: country.currencyCode,
                disabled: !country.supported,
            });
        });

        setCountries(countries);

        currencies.forEach(currency => {
            const group = currency.popular ? 0 : 1;
            currencyField.optionGroups[group].options.push({
                label: currency.name,
                value: currency.code,
                disabled: !currency.supported,
            });
        });
    } catch (e) {
        setError({
            returnStep: 1,
            onRetry: () => setInitialisedData(false),
            message: `
                        Sorry about this, but we're having trouble loading some of the data this form needs. 
                        This might just be temporary - try reloading the page in a moment. 
                        If it keeps happening, please contact us for assistance.
                    `,
            errorDescription: e && e.message,
        });
    }
}

export const storeNameAsDomain = (storeName) => {
    return storeName?.replace('&', 'and').replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
}

export const getCountry = (countries, countryCode) => {
    return countries.filter(country => country.code === countryCode)[0];
}
