/* eslint-env jquery */

import './styles.scss'
import '~templates/global/vendor/forms2.min.js'
import autocomplete from 'autocompleter';
var forms2 = window.MktoForms2
var dataLayer = window.dataLayer || []

var Marketo = {}

/**
 * Makes the labels appear to the right when user enters something in the field
 * and ensures labels on checkboxes always show.
 *
 * @method marketoFloatingLabels
 *
 * @param form {HTMLElement}
 *        The initialized and rendered marketo form
 */
Marketo.floatingLabels = form => {
  // 'fields' variable contains all form inputs barring radioboxes and checkboxes
  // This ensures labels appear correctly for these elements
  var fields = $(form).find('input:not([type="radio"]):not([type="checkbox"])')
  var showLabelFields = $(form).find(
    'input[type="checkbox"], input[type="radio"]'
  )

  // Adds .visible-label class to radioboxes and checkboxes
  // to ensure they are shown and styled correctly in marketo forms
  $.each(showLabelFields, function () {
    $(this)
      .next()
      .addClass('visible-label')
    // Adds .primary-box-label to checkbox/radiobox list primary (header) labels
    $(this)
      .closest('.mktoLogicalField')
      .siblings('label')
      .addClass('visible-label primary-box-label')
    // if the form is using more than 1 checkbox/radiobox (i.e. a list)
    // then apply styling specifically for checkbox/radiobox lists
    // else apply styling specifically for single checkboxs/radioboxs
    if (
      $(this).siblings("input[type='checkbox'], input[type='radio']").length ===
      0
    ) {
      $(this)
        .closest('.mktoFieldWrap')
        .addClass('inline-checkbox-wrap')
      $(this)
        .closest('.mktoLogicalField')
        .siblings('label')
        .addClass('single-box-label')
    } else {
      $(this)
        .closest('.mktoCheckboxList')
        .addClass('primary-box-has-list')
    }
  })

  function toggleLabel(field) {
    var thisInput = $(field)
    var thisInputVal = thisInput.val()
    var thisLabel = thisInput.parents('.mktoFormRow').find('label')

    if (thisInputVal.length > 0) {
      thisLabel.addClass('visible')
    } else {
      thisLabel.removeClass('visible')
    }
  }

  fields.each(function () {
    toggleLabel(this)
  })

  // These fields we do not want to show the labels by default.
  // They are shown floating to the right inside the input when it has a value
  fields.on('keyup', function () {
    toggleLabel(this)
  })
}

/**
 * @method disableSeparators
 *
 * @param form {Form}
 *        The initialized and rendered marketo form
 */
Marketo.disableSeparators = form => {
  var formEl = form.getFormElem()[0]
  var countrySelect = formEl.querySelector('#socpl__Country__c')
  if (countrySelect) {
    var countries = countrySelect.querySelectorAll('OPTION')
    var currentOg

    if (countries.length) {
      countries.forEach(o => {
        if (o.value === '[OPTGROUP]') {
          // Create option group
          currentOg = document.createElement('OPTGROUP')
          // Create the ----- label
          currentOg.label = o.textContent
          countrySelect.appendChild(currentOg)
          countrySelect.removeChild(o)
        } else {
          countrySelect.appendChild(o)
        }
      })
    }
  }
}

/**
 * @method autocompleteFieldFromSelect
 *
 * @param form {Form}
 *        The initialized and rendered marketo form
 */
Marketo.autocompleteFieldFromSelect = form => {
  const formEl = form.getFormElem()[0];
  const autocompleteSelectIDs = [
    '#Referring_Bank_FI__c',
  ];

  autocompleteSelectIDs.forEach(id => {
    const select = formEl.querySelector(id);

    if (select == null) {
      return;
    }

    const options = Array.from(select.querySelectorAll('option')).map(option => ({
      label: option.innerText,
      value: option.value,
    }));

    const input = document.createElement('input');
    input.placeholder = options[0].label;
    options.splice(0, 1);

    select.classList.add('is-autocomplete-select');
    select.after(input);

    input.addEventListener('blur', () => {
      if (!options.map(option => option.value).includes(input.value)) {
        input.value = '';
        select.value = '';
      }
    });

    autocomplete({
      input: input,
      fetch: function(text, update) {
          const suggestions = options.filter(n => n.label.toLowerCase().startsWith(text.toLowerCase()));
          update(suggestions);
      },
      onSelect: function(item) {
          input.value = item.label;
          select.value = item.label;
      }
  });
  });

}

/**
 * @method injectPhoneCountryCode
 *
 * @param form {Form}
 *        The initialized and rendered marketo form
 */
Marketo.injectPhoneCountryCode = form => {
  var formEl = form.getFormElem()[0]
  var phoneInput = formEl.querySelector('#Phone')
  var countrySelect = formEl.querySelector('#socpl__Country__c')
  var countrySwitcher = document.querySelector('.vhq-country-switcher')

  if (countrySwitcher && (phoneInput || countrySelect)) {
    var country = countrySwitcher.dataset.country

    switch (country) {
      case 'us':
        if (countrySelect) countrySelect.value = 'USA';
        if (phoneInput) phoneInput.value = '+1';
        break;

      case 'au':
        if (countrySelect) countrySelect.value = 'Australia';
        if (phoneInput) phoneInput.value = '+61';
        break;

      case 'nz':
        if (countrySelect) countrySelect.value = 'New Zealand';
        if (phoneInput) phoneInput.value = '+64';
        break;

      case 'uk':
        if (countrySelect) countrySelect.value = 'United Kingdom';
        if (phoneInput) phoneInput.value = '+44';
        break;

      case 'in':
        if (countrySelect) countrySelect.value = 'India';
        if (phoneInput) phoneInput.value = '+91';
        break;

      case 'za':
        if (countrySelect) countrySelect.value = 'South Africa';
        if (phoneInput) phoneInput.value = '+27';
        break;

      default:
        break;
    }
  }
}

/**
 * Load the marketo form into the DOM, requires a form elem with valid marketo
 * form id to be present.
 *
 * @method loadMarketoForm
 * @param formConfig {Object}
 *    @param formId {String|Number}
 *           The id of the marketo form to load
 *
 *    @param successTemplate {String}
 *           The html markup to display on succsefull submission of the form.
 *           This will replace the form markup.
 *
 *    @param whenReadyHandler {Function} (Optional)
 *           A callback function to call when the marketo form is ready
 *
 *    @param dataLayerName {String}
 *           To be used as the unique identifier for Google Tag Manager datalayer
 *           push upon successful submission
 *
 *    @param noFooter {Boolean} (Optional)
 *           When true, do not add the 'privacy policy disclosure footer'
 *           Means that all marketo forms will by default say this, unless explicitly stated here
 */
Marketo.loadMarketoForm = (formConfig, cb) => {
  let callback = cb || function () {}

  // Check formConfig
  if (typeof formConfig !== 'object') {
    return callback(Error('First parameter has to be an object'))
  }

  // Set variables
  let {
    formId,
    successTemplate,
    whenReadyHandler,
    dataLayerName,
    noFooter,
    redirectRoute,
    redirectInNewTab,
    closeModal,
    successQueryString,
    contentDownload
  } = formConfig

  // Grab container
  let formContainer = $('#mktoForm_' + formId)

  // Required fields validation
  if (!formId) return callback(Error('Needs a formId'))
  if (!dataLayerName) return callback(Error('Needs a dataLayerName'))

  /**
   * @method success
   * Callback that fires after a successful submission
   */
  const success = () => {
    // Send info to Segment
    if (window.analytics) {
      var email = formContainer.find('[name="Email"]')
      if (email)
        window.analytics.identify({
          email: email.val()
        })

      var locations = formContainer.find('[name="trial_outlet_type__c"]')[0]
      if (locations) {
        if (locations.value === 'Single outlet') {
          dataLayer.push({
            outletType: 'single',
            event: 'marketo-submit'
          });
        } else if (locations.value != null && locations.value !== '') {
          dataLayer.push({
            outletType: 'multi',
            event: 'marketo-submit'
          });
        }
      }

      window.analytics.track('Marketo Form Submitted', {
        form_id: formId,
        page_path: window.location.href
      });
    }

    // Replace key elements with the success message
    $('.js-marketo-title').hide()
    if (successTemplate) {
      formContainer.replaceWith(successTemplate)
    }

    let successQuery = '';
    if (successQueryString) {
      successQuery = successQueryString;
    }

    if (redirectRoute) {
      if (redirectInNewTab) {
        window.open(`${redirectRoute}${successQuery}`, '_blank')
      } else {
        window.location.replace(`${redirectRoute}${successQuery}`)
      }
    }

    if (closeModal && window.Modal) {
      window.Modal.hide();
      if (history.pushState) {
        var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${successQuery}`;
        window.history.pushState({
          path: newurl
        }, '', newurl);
      }
    }

    formContainer.find('.js-form-embedded-header').hide()
    formContainer.find('.js-form-footer').hide()

    // Google tag manager datalayer push event (for tracking purposes)
    if (dataLayerName) {
      dataLayer.push({
        'form name': dataLayerName,
        event: 'Marketo Form Submission'
      })

      if (contentDownload) {
        dataLayer.push({
          'event': 'formSubmit',
          'eventInfo': {
            'category': 'Forms',
            'action': 'form submitted - content',
            'label': formId,
            'label2': dataLayerName,
          }
        })
      }
    }

    if (successTemplate) {
      callback(null, successTemplate)
    }
    return false
  }

  /**
   * @method loaded
   * Callback that fires once a form is loaded
   *
   * @param form {Form}
   *        Rendered and initialized marketo form
   */
  const loaded = form => {
    // Bind the success function to the form
    form.onSuccess(success)

    // Replace the footer with privacy policy information
    try {
      var formFooter = noFooter
        ? ''
        : '<p class="vhq-pt-30 text-center vhq-marketo-disclaimer">By providing your information you agree to our <a href="https://www.vendhq.com/privacy-policy" target="_blank" rel="noopener">privacy policy</a>.</p>'

      formContainer.append(formFooter)
    } catch (e) {
      // Continue if any error occurs
      console.error(e)
    }

    // Render separators as optgroups
    try {
      Marketo.disableSeparators(form)
    } catch (e) {
      // Continue if any error occurs
      console.error(e)
    }

    // Pre-add country code of current site to phone inputs
    try {
      Marketo.injectPhoneCountryCode(form)
    } catch (e) {
      // Continue if any error occurs
      console.error(e)
    }

    // Replace particular select fields with autocomplete inputs
    try {
      Marketo.autocompleteFieldFromSelect(form)
    } catch (e) {
      // Continue if any error occurs
      console.error(e)
    }

    // Floating labels
    try {
      Marketo.floatingLabels(formContainer)
    } catch (e) {
      // Continue if any error occurs
      console.error(e)
    }
  }

  // Load stuff after form is loaded
  forms2.loadForm('//app-sn01.marketo.com', '776-QFO-334', formId, loaded)

  // Initiate reCaptcha when form ready
  forms2.whenReady(function (marketoForm) {
    if (typeof whenReadyHandler === 'function') {
      whenReadyHandler(marketoForm)
    }
  })
}

// Make available in window
window.Marketo = Marketo
