import React from "react";

export const renderError = (error, setError, currentStep, setCurrentStep) => {
    return (
        <>
            <h3>{"That's not right..."}</h3>

            <p>{error.message}</p>

            <div className="error-actions actions">
                <a href="/contact-sales"
                   target="_blank"
                   className="btn btn-default"
                >
                    Contact Us
                </a>
                <button
                    type="submit"
                    className="continue btn btn-primary"
                    onClick={() => {
                        setCurrentStep(error.returnStep || currentStep);
                        setError(null);

                        if (typeof error.onRetry === 'function') {
                            error.onRetry();
                        }
                    }}
                >
                    Try Again
                </button>
            </div>

            {error.errorDescription && (
                <p className="error-description"><b>Error details:</b> {error.errorDescription}</p>
            )}

            {error.fieldErrors && (
                <ul className="error-details">
                    {Object.entries(error.fieldErrors).map(([fieldName, fieldError]) =>
                        <li key={fieldName}>{fieldName}: {fieldError}</li>
                    )}
                </ul>
            )}
        </>
    )
}
