import React, { useEffect, useState } from 'react';

const FakeApp = () => {
    const [render, setRender] = useState(false);

    useEffect(() => {
        setRender(window.innerWidth > 680);
    });

    if (!render) {
        return null;
    }

    return (
        <div className="fake-app">
            <div className="header">
                <div className="logo">
                    <svg viewBox="0 0 703 310" enableBackground="new 0 0 703 310">
                        <g>
                            <path fill="#99a2a6" d="M597.5,0H30C13.4,0,0,13.4,0,30v250c0,16.6,13.4,30,30,30h567.5c11.2,0,21.5-6.3,26.6-16.2l74.5-125
                                    c4.5-8.6,4.5-18.9,0-27.5l-74.5-125C619,6.3,608.7,0,597.5,0"/>
                            <path fill="#3a4a53" d="M652,155c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15c0-8.3,6.7-15,15-15C645.3,140,652,146.7,652,155"/>
                            <path fill="#3a4a53" d="M146.9,123.2H169c1.5,0,2.4,1.5,1.8,2.9l-57.4,118.1c-1.1,2.2-4.3,2.3-5.4,0L50.2,126.1
                                    c-0.7-1.3,0.3-2.9,1.8-2.9h22.2c0.8,0,1.5,0.5,1.8,1.2l34.7,75.2l34.4-75.2C145.4,123.7,146.1,123.2,146.9,123.2"/>
                            <path fill="#3a4a53" d="M268.7,170.7c-1.3-9-4.7-16.2-10.3-21.8c-5.9-5.9-13.4-8.8-22.3-8.8c-9.5,0-17.4,2.7-23.8,8.2
                                    c-6.4,5.5-10.4,12.9-12,22.3H268.7z M275.4,206.8l14.9,8.7c1,0.6,1.3,1.9,0.7,2.8c-13.4,21-32.2,31.4-56.6,31.4
                                    c-17.8,0-32.2-6.4-43.2-19.3c-10.7-12.6-16-29.4-16-50.5c0-7.5,1.6-15.3,4.8-23.5c5.9-15.2,15.9-25.7,29.9-31.7
                                    c7.4-3.1,15.5-4.7,24.5-4.7c25.6,0,42.9,10.8,51.7,32.3c4.2,10.1,6.1,19.7,5.7,28.9l-0.2,5.3c0,1.1-0.9,1.9-2,1.9h-90.4
                                    c0,11.5,3.4,21.1,10.1,28.8c7.1,8,16.4,12,27.8,12c14.3,0,28.3-9.4,35.5-21.6C273.2,206.5,274.4,206.2,275.4,206.8"/>
                            <path fill="#3a4a53" d="M315.4,123.2h19.3c1.1,0,2,0.9,2,2v15.4c9-13.8,21.6-20.7,37.8-20.7c28.4,0,42.7,17.4,42.7,52.3v71.3
                                    c0,1.1-0.9,2-2,2h-19.4c-1.1,0-2-0.9-2-2v-71.8c0-10.4-1.8-18-5.5-22.8c-4.2-5.5-11.3-8.3-21.4-8.3c-20.1,0-30.2,12.7-30.2,38.1
                                    v64.8c0,1.1-0.9,2-2,2h-19.3c-1.1,0-2-0.9-2-2V125.2C313.4,124.1,314.3,123.2,315.4,123.2"/>
                            <path fill="#3a4a53" d="M535.8,185c0-17.6-6.1-30.2-18.3-37.5c-6-3.6-12.8-5.4-20.4-5.4c-15.7,0-26.6,7.7-33,23
                                    c-2.7,6.6-4.1,13.4-4.1,20.4c0,14.5,5,26.1,15.1,34.7c5.6,4.8,12.7,7.1,21.4,7.1c16.9,0,28.6-7.3,35.1-22
                                    C534.4,199,535.8,192.3,535.8,185 M540.6,59.7H560c1.1,0,2,0.9,2,2v181.8c0,1.1-0.9,2-2,2h-19.4c-1.1,0-2-0.9-2-2v-17.9
                                    c-10.6,16-24.5,24.1-41.8,24.1c-19.2,0-34.2-7.2-45-21.5c-9.5-12.6-14.3-26.9-14.3-42.6c0-12.3,2.4-23.4,7.1-33.3
                                    c5.3-11,12.9-19.3,22.8-24.9c8.7-4.9,18.2-7.4,28.3-7.4c8.4,0,16.7,2.3,24.8,6.9c8.2,4.6,14.1,10.5,18,17.8V61.7
                                    C538.6,60.6,539.5,59.7,540.6,59.7"/>
                        </g>
                    </svg>
                </div>
            </div>
            <div className="cols">
                <div className="sidebar">
                    <div className="vd-sidebar-tabs">
                        <div className="vd-nav-item vd-nav-item--wistera">
                            <a className="vd-nav-item-action" href="#">
                                <i className="fa fa-home vd-icon vd-nav-item-icon"/>
                                <div className="vd-nav-item-label">Home</div>
                            </a>
                        </div>
                        <div className="vd-nav-item vd-nav-item--ocean-green">
                            <a className="vd-nav-item-action" href="#">
                                <i className="vd-i vd-i-sell vd-icon vd-nav-item-icon"/>
                                <div className="vd-nav-item-label">Sell</div>
                            </a>
                        </div>
                        <div className="vd-nav-item vd-nav-item--rajah">
                            <a className="vd-nav-item-action" href="#">
                                <i className="fa fa-area-chart vd-icon vd-nav-item-icon"/>
                                <div className="vd-nav-item-label">Reporting</div>
                            </a>
                        </div>
                        <div className="vd-nav-item vd-nav-item--carnation">
                            <a className="vd-nav-item-action" href="#">
                                <i className="fa fa-tags vd-icon vd-nav-item-icon"/>
                                <div className="vd-nav-item-label">Products</div>
                            </a>
                        </div>
                        <div className="vd-nav-item vd-nav-item--wistera">
                            <a className="vd-nav-item-action" href="#">
                                <i className="vd-i vd-i-customers vd-icon vd-nav-item-icon"/>
                                <div className="vd-nav-item-label">Customers</div>
                            </a>
                        </div>
                        <div className="vd-nav-item vd-nav-item--fiord">
                            <a className="vd-nav-item-action" href="#">
                                <i className="vd-i vd-i-setup vd-icon vd-nav-item-icon"/>
                                <div className="vd-nav-item-label">Setup</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="content" />
            </div>
        </div>
    );
};

export default FakeApp;
