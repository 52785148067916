import './components/organisms/notice'
import './components/organisms/promobar'
import './components/organisms/topbar'
import Navigation from './components/organisms/navigation'
import './components/organisms/navigationAlt'
import SiteSwitcher from './components/molecules/siteSwitcher'
import Footer from './components/organisms/footer'
import './layout.scss'

// save Google Ads Click ID URL parameter to localStorage, for re-use on subsequent pages
const urlParams = new URLSearchParams(window.location.search);
const gclid = urlParams.get('gclid');
if (gclid != null) {
  window.localStorage.setItem('gclid', gclid);
}

document.addEventListener('DOMContentLoaded', () => {
  Navigation.onLoad();
  Navigation.stickyNav();
  SiteSwitcher();
  Footer.onLoad();
});
