import "./styles.scss";
import LazyLoad from 'vanilla-lazyload';

document.addEventListener('DOMContentLoaded', () => {
  const lazyLoadInstance = new LazyLoad({ // eslint-disable-line
    elements_selector: '.js-lazy',
    unobserve_entered: true,
    threshold: 0
  });

  const inPlaceVideos = Array.from(document.querySelectorAll('.js-in-place-video'));

  inPlaceVideos.forEach(inPlaceVideo => {
    inPlaceVideo.addEventListener('click', (e) => {
      e.preventDefault();

      const videoId = inPlaceVideo.dataset.videoId;
      const videoService = inPlaceVideo.dataset.service;
      let videoSrc = `https://www.youtube-nocookie.com/embed/${videoId}?modestbranding=1&autoplay=1&controls=1&loop=1&playsinline=1&rel=0`;
      if (videoService === 'vimeo') {
        videoSrc = `https://player.vimeo.com/video/${videoId}?autoplay=1&title=0&byline=0&portrait=0`;
      }

      const imageWrap = inPlaceVideo.querySelector('.c-in-place-video__video-wrap');
      imageWrap.classList.add('has-video');
      imageWrap.innerHTML = `<iframe src="${videoSrc}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

      if (inPlaceVideo.classList.contains('js-demo-preview')) {
        // Create an event.
        var event = document.createEvent('Event');
        // Define that the event name is 'demo'.
        event.initEvent('demo', true, true);
        // dispatch the event on the window
        window.dispatchEvent(event);
      }
    });
  });
});
