import React, { createContext, useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { createPortal } from 'react-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { hide, setCurrentStep, setFormData } from './store/signupSlice';

import { handleCookies, initialiseData } from './helpers/helpers';
import processSalesforceLead from './helpers/processSalesforceLead';
import { createTrialAccount } from './helpers/createTrialAccount';
import { renderError } from './helpers/renderError.jsx';
import Step from './components/Step.jsx';

import step1Fields from './data/fields/step1.json';
import step2Fields from './data/fields/step2.json';
import step3Fields from './data/fields/step3.json';
import step4Fields from './data/fields/step4.json';

export const SignupContext = createContext(null)

const BaseSignupApp = (props) => {
    const dispatch = useDispatch();
    const { stepsConfig, dismissed, currentStep, formOptions, formData, dismissable, mediaMode } = useSelector((state) => state.signup);
    const { closeLink, children } = props;

    const steps = stepsConfig.map(step => (
        <Step
            key={step.key}
            step={step.step}
            fieldGroup={step.fieldGroup}
            title={step.title}
            subtitle={step.subtitle}
            buttonText={step.buttonText}
        />
    ));

    const modalClose = () => {
        dispatch(hide());

        window.top.postMessage({ closeFrame: true }, '*');
    };

    const recaptcha = useRef();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const [initialisedData, setInitialisedData] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [continueUrl, setContinueUrl] = useState(null);
    const [mini, setMini] = useState(false);

    const setActiveStep = stepNum => dispatch(setCurrentStep(stepNum));

    const data = formData;
    const setData = async (newData) => {
        await dispatch(setFormData({ ...data, ...newData }));
    }


    const [countries, setCountries] = useState([]);
    const [manualCountry, setManualCountry] = useState(undefined);
    const [changeDomain, setChangeDomain] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(null);
    const [error, setError] = useState(null);

    const fields = [
        ...step1Fields,
        ...step2Fields,
        ...step3Fields,
        ...step4Fields,
    ];

    const formMethods = useForm({
        mode: 'onTouched',
    });

    useEffect(() => {
        setActiveStep(currentStep);

        // Initial data change implies a mini form submission, push dataLayer
        pushDatalayer();
    }, [formData]);

    useEffect(() => {
        updateLead();
    }, [currentStep]);

    useEffect(() => {
        handleCookies();
    });

    useEffect(() => {
        initialiseData(initialisedData, fields, setCountries, setInitialisedData, setError, error, data, setData, setLoading, setLoadingText);
    }, [initialisedData]);

    useEffect(() => {
        if (error === null) {
            return;
        }

        // update the lead with a note of the error message
        updateLead();
    }, [error]);

    useEffect(() => {
        if (!formOptions.salesforceLeadType) {
            return;
        }

        const searchParams = new URLSearchParams(window.location.search);
        const urlFrom = searchParams.get('from');

        if (formOptions.trackVWO || urlFrom === 'nav') {
            // VWO AB Test Goal Tracking (Submitted Step 1)
            if (currentStep === 1) {
                window._vis_opt_queue = window._vis_opt_queue || [];
                window._vis_opt_queue.push(function () {
                    _vis_opt_goal_conversion(205);
                });
            }
        }
    }, [formOptions]);

    const updateLead = () => {
        processSalesforceLead(currentStep, data, countries, executeRecaptcha, error, setError, formOptions);

        if (window.analytics) {
            window.analytics.identify({
                email: data.email,
                firstName: data.first_name,
                lastName: data.last_name,
                phone: data.phone,
                country: data.country,
            });
        }
    }

    const onRecaptchaProblem = () => {
        console.error(`A reCAPTCHA problem (error or expiry) has occurred`);

        if (recaptchaToken != null) {
            console.log(`Resetting reCAPTCHA token`)
            recaptcha.current.reset();
            setRecaptchaToken(null);
        }
    }

    const pushDatalayer = () => {
        if (!data.first_name || !data.last_name) {
            return;
        }

        const dataLayer = {
            submissionStep: currentStep,
            event: formOptions.gtmTriggerSubmitEventName,
        };

        if (data.locations != null) {
            dataLayer.outletType = data.locations === 'Single outlet' ? 'single' : 'multi';
        }

        window.dataLayer.push(dataLayer);
    }

    const prevStep = async () => {
        setSubmitted(false);
        setActiveStep(currentStep > 1 ? currentStep - 1 : 1);
    }

    const nextStep = async () => {
        setSubmitted(false);

        pushDatalayer();

        // Segment Tracking
        if (window.analytics) {
            window.analytics.track('Signup Form', {
                step_completed: currentStep, // i.e. when submitting step 2 and going to step 3
                lead_type: formOptions.salesforceLeadType,
                content_type: formOptions.contentType,
                page_path: window.location.href
            });
        }

        const searchParams = new URLSearchParams(window.location.search);
        const urlFrom = searchParams.get('from');

        if (formOptions.trackVWO || urlFrom === 'nav') {
            // VWO AB Test Goal Tracking (Init queue)
            window._vis_opt_queue = window._vis_opt_queue || [];

            // VWO AB Test Goal Tracking (Submitted Step 1)
            if (currentStep === 1) {
                window._vis_opt_queue.push(function () { _vis_opt_goal_conversion(201); });
            }

            // VWO AB Test Goal Tracking (Submitted Step 2)
            if (currentStep === 2) {
                window._vis_opt_queue.push(function () { _vis_opt_goal_conversion(202); });
            }

            // VWO AB Test Goal Tracking (Submitted Step 3)
            if (currentStep === 3) {
                window._vis_opt_queue.push(function () { _vis_opt_goal_conversion(203); });
            }

            // VWO AB Test Goal Tracking (Submitted Step 4 / Created Trial)
            if (currentStep === steps.length) {
                window._vis_opt_queue.push(function () {
                    _vis_opt_goal_conversion(204);
                });
            }
        }

        if (currentStep === steps.length) {
            updateLead();

            const continueUrl = await createTrialAccount(fields, data, setLoading, setError, recaptcha, recaptchaToken, setRecaptchaToken);

            if (!continueUrl) {
                return;
            }

            if (formOptions.signupNewTab) {
                setContinueUrl(continueUrl);
            } else {
                window.top.location.href = continueUrl;
            }

            return;
        }

        setActiveStep(currentStep + 1);
    }

    const renderStep = () => {
        return steps[currentStep - 1];
    }

    const renderFormBoxContent = () => {
        if (error && window.vhqSiteConfig.env !== 'dev') {
            return renderError(error, setError, currentStep, setActiveStep);
        }

        if (continueUrl) {
            return (
                <div className="success">
                    <div className="heading">
                        {formOptions.fileUrl && (
                            <div className="signup-download-file">
                                <p>
                                    Thanks! Your download is ready:
                                    <a href={formOptions.fileUrl} target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                            data-prefix="fas" data-icon="download"
                                            className="svg-inline--fa fa-download fa-w-16" role="img" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" />
                                        </svg>
                                        Download guide
                                    </a>
                                </p>
                            </div>
                        )}
                        {formOptions.fileEmail && !formOptions.fileUrl && (
                            <div className="signup-download-file">
                                <p>
                                    Thanks! Check your inbox to download the guide.
                                </p>
                            </div>
                        )}
                        <h1>Thanks, {data.first_name}</h1>
                        <p className="subtitle">Your store has been created. Step inside when ready.</p>
                    </div>
                    <div className="actions">
                        <a href={continueUrl} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                            Go to Your Store Now
                        </a>

                        {formOptions.demoVideo && (
                            <p>Your store will open in a new tab, so you can continue watching the demo video.</p>
                        )}
                    </div>
                </div>
            )
        }

        if (loading) {
            return (
                <div className="signup-loader">
                    <div className="signup-loader__spinner" />
                    {loadingText !== '' && <span className="signup-loader__text">{loadingText !== null ? loadingText : "We're setting up your store. One moment, please."}</span>}
                </div>
            );
        }

        if (currentStep === 3 && countries.length === 0) {
            return (
                <div className="signup-loader">
                    <div className="signup-loader__spinner" />
                </div>
            );
        }

        return (
            <>
                {renderStep()}

                {currentStep === 1 && (
                    <div className="terms">
                        <p>
                            By clicking “Continue” you agree to Vend’s
                            &nbsp;
                            <a href="https://www.vendhq.com/terms-of-use" target="_blank" rel="noopener noreferrer">
                                terms of use
                            </a>
                            &nbsp;
                            <br />
                            and confirm that you’ve read and acknowledged Vend’s
                            &nbsp;
                            <a href="https://www.vendhq.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                                privacy policy
                            </a>.
                        </p>
                    </div>
                )}

                {currentStep === steps.length && (
                    <div className="terms">
                        <p>
                            This form is protected by reCAPTCHA and the Google
                            &nbsp;
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                                privacy policy
                            </a>
                            &nbsp;
                            and
                            &nbsp;
                            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                                terms of service
                            </a>
                            &nbsp;
                            also apply.
                        </p>
                    </div>
                )}

                {currentStep <= steps.length && (
                    <div className="progress-bar">
                        {currentStep}/{steps.length}
                        <div className="completed" style={{ width: `${(currentStep * 100) / steps.length}%` }} />
                    </div>
                )}

                {error && window.vhqSiteConfig.env === 'dev' && (
                    <div className="dev-error">
                        <h5>Test Mode - Error:</h5>
                        <p>{error.message}</p>
                    </div>
                )}

                <div className="spacer" />
            </>
        );
    }

    const context = {
        data,
        setData,
        currentStep,
        steps,
        fields,
        countries,
        setError,
        dismissed,
        manualCountry,
        setManualCountry,
        changeDomain,
        setChangeDomain,
        submitted,
        setSubmitted,
        nextStep,
        prevStep,
        mini,
        setMini,
    };

    const onSubmit = (newData) => {
        setSubmitted(true);
        setData({ ...data, ...newData });
    };

    const onError = () => {
        setSubmitted(true);
    }

    return createPortal(
        <SignupContext.Provider value={context}>
            <div className={`signup-container is-step${currentStep}${mediaMode ? ' is-mini' : ''}${dismissed ? ' is-dismissed' : ''}${error !== null ? ' is-error' : ''}${continueUrl ? ' is-success' : ''}`} onClick={dismissable ? modalClose : () => { }}>
                <FormProvider {...formMethods}>
                    {children}
                    <form className="form-box" onSubmit={formMethods.handleSubmit(onSubmit, onError)} noValidate onClick={e => { e.stopPropagation() }}>
                        {(closeLink && currentStep === steps.length && !continueUrl && !loading) && (
                            <a href={closeLink} className="dismiss">
                                <i className="fal fa-times" aria-hidden="true" />
                            </a>
                        )}

                        {dismissable && (
                            <button type="button" className="dismiss" onClick={modalClose}>
                                <i className="fal fa-times" aria-hidden="true" />
                            </button>
                        )}

                        {renderFormBoxContent()}

                        <ReCAPTCHA
                            ref={recaptcha}
                            size="invisible"
                            sitekey={window.vhqSiteConfig.recaptchaSiteKey}
                            onExpired={onRecaptchaProblem}
                            onErrored={onRecaptchaProblem}
                        />
                    </form>
                </FormProvider>
            </div>
        </SignupContext.Provider>,
        document.body
    )
}

BaseSignupApp.defaultProps = {
    closeLink: null,
    initialData: {},
    modalClose: () => { },
    dismissed: false,
};

BaseSignupApp.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.node).isRequired,
    closeLink: PropTypes.string,
    children: PropTypes.node,
    initialData: PropTypes.objectOf(PropTypes.string),
    modalClose: PropTypes.func,
    dismissed: PropTypes.bool,
};

export default BaseSignupApp;
