import './styles.scss'

import Swiper from "swiper";

/* eslint-disable no-unused-vars */
var swiper = new Swiper(".swiper-container", {
  grabCursor: true,
  loop: true,
  spaceBetween: 10,
  slidesPerView: "auto",
  centeredSlides: true,
  loopedSlides: 99,
  slideToClickedSlide: true,
  slideToClickedSlide: true,
  breakpointsInverse: true,
  breakpoints: {
    768: {
      spaceBetween: 20
    }
  }
});
/* eslint-enable no-unused-vars */