import Cookies from "js-cookie";

const executeRecaptchaV2 = async (recaptcha, setLoading, setError, setRecaptchaToken) => {
    console.log(`Executing reCAPTCHA challenge`);

    try {
        const token = await recaptcha.current.executeAsync();
        setRecaptchaToken(token);

        return token;
    } catch (e) {
        setLoading(false);
        setError({
            returnStep: 4,
            message: `
                        Sorry - we tried to use reCAPTCHA to verify that you're not a robot, but something went wrong.
                        You might be able to try again, or if this keeps happening please contact us for assistance.
                    `,
            errorDescription: e && e.message,
        });

        // send error to Raygun
        if (window.rg4js) {
            window.rg4js('send', {
                error: new Error(`A reCAPTCHA error occurred on signup: ${e && e.message}`),
            });
        }

        return null;
    }
}

export const createTrialAccount = async (fields, data, setLoading, setError, recaptcha, recaptchaToken, setRecaptchaToken) => {
    setLoading(true);

    const searchParams = new URLSearchParams(window.location.search);

    console.log('createTrialAccount', data);

    const recaptchaResponse = recaptchaToken != null ? recaptchaToken : await executeRecaptchaV2(recaptcha, setLoading, setError, setRecaptchaToken);

    const signupData = {
        signup: data,
        [window.vhqSiteConfig.csrfTokenName]: window.vhqSiteConfig.csrfToken,
        'g-recaptcha-response': recaptchaResponse,
        'tracking_cookie': Cookies.get('_mkto_trk'),
        'segment_anonymous_id': window.analytics !== undefined && window.analytics.user !== undefined ? window.analytics.user().anonymousId() : null,
        'sf_lead_id': searchParams.get('sf_lead_id'),
        'campaign': Cookies.get('campaign'),
        'partner_code': Cookies.get('partner_code'),
        'plan': Cookies.get('trial_pricing_plan'),
    };

    try {
        const response = await fetch('/actions/signup/retailer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(signupData),
        });
        const result = await response.json();

        if (!result.success) {
            console.error(result.message, result.errors);

            setLoading(false);
            setError({
                returnStep: 4,
                message: `
                        Sorry - it didn't work. An error occurred during creation of your trial account.
                        You might be able to try again, or if this keeps happening please contact us for assistance.
                    `,
                errorDescription: result.message,
                fieldErrors: result.errors,
            });

            // send error to Raygun
            if (window.rg4js) {
                window.rg4js('send', {
                    error: new Error(`Signup perform returned non-success result`),
                    customData: {
                        data: signupData,
                        result,
                    },
                });
            }

            return undefined;
        }

        // set auth cookies
        if (result.data.loginURL) {
            try {
                await fetch(result.data.loginURL, {
                    credentials: 'include',
                    mode: 'no-cors',
                });
            } catch (e) {
                // ignore any errors fetching the loginURL
                // this just means the retailer will have to sign in manually
            }
        }

        return `https://${result.data.storeURL}/welcome`;
    } catch (e) {
        setLoading(false);
        setError({
            returnStep: 4,
            message: `
                        Sorry - it didn't work. An error occurred during creation of your trial account.
                        You might be able to try again, or if this keeps happening please contact us for assistance.
                    `,
            errorDescription: e.message,
        });

        // send error to Raygun
        if (window.rg4js) {
            window.rg4js('send', {
                error: new Error(`Signup perform threw an error: ${e.message}`),
                customData: {
                    data: signupData,
                },
            });
        }

        return undefined;
    }
}
