import React from 'react';
import { createPortal } from 'react-dom';
import PhoneInput from '../../signupApp/components/PhoneInput.jsx';
import { FormProvider, useForm } from 'react-hook-form';
import step1Fields from '../../signupApp/data/fields/step1.json';
import step2Fields from '../../signupApp/data/fields/step2.json';
import { validateEmail, validateName } from '../../signupApp/helpers/validation';
import { setCurrentStep, setFormOptions, setSteps, show, setFormData } from '../../signupApp/store/signupSlice';
import { useDispatch, useSelector } from 'react-redux';

const HeroBannerSignupForm = () => {
    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.signup);

    const formMethods = useForm({
        mode: 'onTouched',
    });

    const { register, errors, formState } = formMethods;
    const { dirtyFields, submitCount } = formState;

    const onSubmit = async (newData) => {
        await dispatch(setFormOptions({
            ...window.heroBannerFormSignupOptions,
            salesforceLeadType: 'Content Download',
            gtmTriggerSubmitEventName: 'download-signup-submit',
        }));

        await dispatch(setFormData({ ...formData, ...newData }));

        await dispatch(setSteps([
            { key: 'step_1', step: 1, fieldGroup: 1, title: 'Get access to our exclusive guide', subtitle: 'Plus heaps more great online resources' },
            { key: 'step_2', step: 2, fieldGroup: 2, title: 'How should we contact you?' },
            { key: 'step_3', step: 3, fieldGroup: 3, title: 'Tell us about your business' },
            { key: 'step_4', step: 4, fieldGroup: 4, title: 'Sign up for your free Vend 14 day trial', buttonText: 'Start your trial' },
        ]));

        await dispatch(setCurrentStep(3));
        await dispatch(show());
    };

    const onError = () => {
        // 🤷‍
    }

    const fields = [
        ...step1Fields,
        ...step2Fields,
    ];

    const renderInput = (field) => {
        const registerValidation = register({
            // Standard validation rules
            required: field.validation.required,
            minLength: field.validation.minLength,
            maxLength: field.validation.maxLength,

            // Custom validation rules
            validate: {
                name: value => validateName(value, field),
                email: value => validateEmail(value, field),
            }
        });

        let defaultValue = formData[field.key] || field.value;

        if (field.key === 'phone') {
            return <PhoneInput
                register={registerValidation}
                defaultValue={formData['phone_validation']}
                defaultValueWithDialCode={formData['phone']}
            />;
        }

        return (
            <input
                key={field.key}
                name={field.key}
                ref={registerValidation}
                type={field.type}
                className="form-field grid-12 form-field--border vd-input"
                defaultValue={defaultValue}
                placeholder={field.placeholder}
            />
        );
    }

    const containerEl = document.getElementById('herobannerform-signup-app');

    if (!containerEl) {
        return null;
    }

    return createPortal(
        <FormProvider {...formMethods}>
            <form className="mini-signup-form" onSubmit={formMethods.handleSubmit(onSubmit, onError)} noValidate>
                {fields.map(field => {
                    if (field.key === 'phone') {
                        const validationKey = 'phone_validation';
                        const hasErrors = errors[validationKey] && (dirtyFields[validationKey] || submitCount > 0);

                        return (
                            <div key={field.key} className={`field field--${field.key} ${hasErrors ? 'is-invalid' : ''} show-validation`}>
                                <label htmlFor={field.key}>
                                    {field.label}
                                </label>

                                {renderInput(field)}

                                {hasErrors && (
                                    <ul>
                                        <li>{errors[validationKey].message}</li>
                                    </ul>
                                )}
                            </div>
                        );
                    }

                    return (
                        <div key={field.key} className={`field field--${field.key} ${errors[field.key] && (dirtyFields[field.key] || submitCount > 0) ? 'is-invalid' : ''} show-validation`}>
                            <label htmlFor={field.key}>
                                {field.label}
                            </label>

                            {renderInput(field)}

                            {errors[field.key] && (dirtyFields[field.key] || submitCount > 0) && (
                                <ul>
                                    <li>{errors[field.key].message}</li>
                                </ul>
                            )}
                        </div>
                    );
                })}

                <button type="submit" className={`continue btn btn-primary`}>Submit</button>
            </form>
        </FormProvider>,
        containerEl
    );
}

export default HeroBannerSignupForm;
