import Cookies from 'js-cookie';
import './styles.scss'

document.addEventListener('DOMContentLoaded', () => {
  const notice = document.querySelector('.js-notice');
  const noticeClose = document.querySelector('.js-notice-close');

  if (!notice || !noticeClose) {
    return;
  }

  if (!Cookies.get(`noticeHidden_${notice.dataset.noticeId}`)) {
    notice.style.display = 'block';
  }

  noticeClose.addEventListener('click', () => {
    notice.style.display = 'none';
    Cookies.set(`noticeHidden_${notice.dataset.noticeId}`, true);
  });
})
