import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { SignupContext } from '../../../../components/organisms/signupApp/BaseSignupApp.jsx';
import { focusField } from '../../../../components/organisms/signupApp/helpers/helpers';
import { setMediaMode, setFormOptions } from '../../../../components/organisms/signupApp/store/signupSlice';

const Video = (props) => {
    const dispatch = useDispatch();
    const { currentStep, formOptions } = useSelector((state) => state.signup);
    const { steps, changeDomain, data } = useContext(SignupContext);
    const { control } = useFormContext();
    const [isPaused, setIsPaused] = useState(true);
    const [paused, setPaused] = useState(true);
    const { closed, setClosed } = props;

    const handlePlayerEnd = () => {
        setClosed(true);
    };

    const handlePlayerPlay = () => {
        setIsPaused(false);
    }

    const handlePlayerPause = () => {
        setIsPaused(true);
    }

    const setMini = async (isMediaMode) => {
        await dispatch(setMediaMode(isMediaMode));
    }

    const setOpenInNewTab = async (openInNewTab) => {
        await dispatch(setFormOptions({
            ...formOptions,
            signupNewTab: openInNewTab,
        }));
    }

    useEffect(() => {
        setClosed(currentStep !== steps.length);
    }, [currentStep]);

    useEffect(() => {
        if (closed) {
            setOpenInNewTab(false);
            setPaused(true);
            setMini(false);

            return;
        }

        setOpenInNewTab(true);
        setPaused(false);
        setMini(true);
        focusField(control, changeDomain);
    }, [closed]);

    if (!formOptions || !formOptions.demoSingleVideo || !formOptions.demoMultiVideo) {
        return null;
    }

    return (
        <div className={`video-container ${closed ? 'is-hidden' : ''}`}>
            {isPaused && (
                <button type="button" onClick={() => setClosed(true)}>
                    <span className="fa-stack">
                        <i className="fas fa-square fa-stack-2x" />
                        <i className="fal fa-times fa-stack-1x fa-inverse" />
                    </span>
                </button>
            )}

            <Vimeo
                className="video-player"
                video={`https://player.vimeo.com/video/${data.locations === 'Single outlet' ? formOptions.demoSingleVideo : formOptions.demoMultiVideo}`}
                paused={paused}
                showByline={false}
                responsive={true}
                autoplay={false}
                showTitle={false}
                showPortrait={false}
                transparent={false}
                color="41AF4B"
                onEnd={handlePlayerEnd}
                onPlay={handlePlayerPlay}
                onPause={handlePlayerPause}
            />
        </div>
    );
};

Video.propTypes = {
    closed: PropTypes.bool.isRequired,
    setClosed: PropTypes.func.isRequired,
};

export default Video;
