/* global $ */

$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault()

  let href = $.attr(this, 'href')
  var scrollTop = 0

  // Check if anchor is just #, in that case we want scrollTop to be 0
  if (href !== '#') {
    // Otherwise we set scrollTop to be 120px higher than the element offset
    // This is to not have the navigation overlay the headline
    scrollTop = Math.max(0, $($.attr(this, 'href')).offset().top - 120) // Math.max makes sure we don't have negative numbers :)
  }

  $('html, body').animate(
    {
      scrollTop
    },
    500
  )
})
