import React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { show, setCurrentStep, setSteps, setFormOptions } from '../../signupApp/store/signupSlice';

const HeroTwoColSignupApp = () => {
    const dispatch = useDispatch();

    const containerEl = document.getElementById('hero-two-col-signup-app');

    if (!containerEl) {
        return null;
    }

    return createPortal(
        <button
            type="submit"
            className={`continue btn btn-block btn-secondary`}
            onClick={async () => {
                await dispatch(setFormOptions({
                    ...window.heroTwoColSignupOptions,
                    salesforceLeadType: 'Content Download',
                    gtmTriggerSubmitEventName: 'download-signup-submit',
                }));

                await dispatch(setSteps([
                    { key: 'step_1', step: 1, fieldGroup: 1, title: 'Get access to our exclusive guide', subtitle: 'Plus heaps more great online resources' },
                    { key: 'step_2', step: 2, fieldGroup: 2, title: 'How should we contact you?' },
                    { key: 'step_3', step: 3, fieldGroup: 3, title: 'Tell us about your business' },
                    { key: 'step_4', step: 4, fieldGroup: 4, title: 'Sign up for your free Vend 14 day trial', buttonText: 'Start your trial' },
                ]));
                await dispatch(setCurrentStep(1));
                await dispatch(show());
            }}
        >
            {window.heroTwoColSignupOptions.downloadButtonText}
        </button>,
        containerEl,
    );
}

export default HeroTwoColSignupApp;
