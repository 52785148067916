import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import FakeApp from './components/FakeApp.jsx';
import { show, setSteps, setFormOptions, setModalMode, setDismissable } from '../../../components/organisms/signupApp/store/signupSlice';

const TrialSignupApp = () => {
    const [initialised, setInitialised] = useState(false);
    const dispatch = useDispatch();

    const initialise = async () => {
        await dispatch(setFormOptions({
            ...window.navigationSignupOptions,
            salesforceLeadType: 'Trial Intent',
            gtmTriggerSubmitEventName: 'progressive-trial-signup-submit',
        }));

        await dispatch(setSteps([
            { key: 'step_1', step: 1, fieldGroup: 1, title: 'Hi, nice to meet you', subtitle: 'Start your free 14 day trial of Vend' },
            { key: 'step_2', step: 2, fieldGroup: 2, title: 'How should we contact you?' },
            { key: 'step_3', step: 3, fieldGroup: 3, title: 'Tell us about your business' },
            { key: 'step_4', step: 4, fieldGroup: 4, title: 'Sign up for your free Vend 14 day trial', buttonText: 'Start your trial' },
        ]));

        await dispatch(show());
        await dispatch(setModalMode());
        await dispatch(setDismissable(false))

        setInitialised(true);
    }

    const containerEl = document.getElementById('progressive-signup-app');

    useEffect(() => {
        if (initialised || !containerEl) {
            return;
        }

        initialise();
    });

    if (!containerEl) {
        return null;
    }

    return createPortal(
        <FakeApp />,
        containerEl,
    );
}
export default TrialSignupApp;