import React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { show, setSteps, setCurrentStep, setFormOptions } from '../../../organisms/signupApp/store/signupSlice.js';

const RiverRowSignupButton = () => {
    const containerEls = Array.from(document.querySelectorAll('.riverrow-signup-app'));

    return (
        <>
            {containerEls.map((containerEl, i) => (
                <RiverRowSignupButtonEl key={`riverrow-signup-app_${i}`} containerEl={containerEl} />
            ))}
        </>
    );
}

const RiverRowSignupButtonEl = (props) => {
    const dispatch = useDispatch();

    const { containerEl } = props;

    return createPortal(
        <button
            type="submit"
            className={`continue btn btn-primary`}
            onClick={async () => {
                await dispatch(setFormOptions({
                    ...window.riverRowSignupOptions,
                    fileTitle: containerEl.dataset.fileTitle,
                    fileSubtitle: containerEl.dataset.fileSubtitle,
                    fileUrl: containerEl.dataset.fileUrl,
                    fileEmail: containerEl.dataset.fileEmail,
                    salesforceLeadType: 'Content Download',
                    gtmTriggerSubmitEventName: 'download-signup-submit',
                }));

                await dispatch(setSteps([
                    { key: 'step_1', step: 1, fieldGroup: 1, title: 'Get access to our exclusive guide', subtitle: 'Plus heaps more great online resources' },
                    { key: 'step_2', step: 2, fieldGroup: 2, title: 'How should we contact you?' },
                    { key: 'step_3', step: 3, fieldGroup: 3, title: 'Tell us about your business' },
                    { key: 'step_4', step: 4, fieldGroup: 4, title: 'Sign up for your free Vend 14 day trial', buttonText: 'Start your trial' },
                ]));
                await dispatch(setCurrentStep(1));
                await dispatch(show());
            }}
        >
            {containerEl.dataset.buttonText}
        </button>,
        containerEl,
    );
}

export default RiverRowSignupButton;
