import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dismissed: true,
    modalMode: false,
    mediaMode: false,
    dismissable: true,
    currentStep: 1,
    stepsConfig: [],
    formOptions: {},
    formData: {},
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        show: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.dismissed = false;
        },
        hide: (state) => {
            state.dismissed = true;
        },
        setModalMode: (state) => {
            state.modalMode = true;
        },
        setMediaMode: (state, action) => {
            state.mediaMode = action.payload;
        },
        setDismissable: (state, action) => {
            state.dismissable = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setSteps: (state, action) => {
            state.stepsConfig = action.payload;
        },
        setFormOptions: (state, action) => {
            state.formOptions = action.payload;
        },
        setFormData: (state, action) => {
            state.formData = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { show, hide, setCurrentStep, setSteps, setFormOptions, setFormData, setModalMode, setDismissable, setMediaMode } = signupSlice.actions

export default signupSlice.reducer
