import React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { show, setCurrentStep, setFormOptions, setSteps } from '../../../../../components/organisms/signupApp/store/signupSlice';

const TwoColumnButtonTrigger = () => {
    const dispatch = useDispatch();

    const containerEl = document.getElementById('two-col-signup-app');

    if (!containerEl) {
        return null;
    }

    return createPortal(
        <button
            type="submit"
            className={`continue c-button c-button--inverse`}
            onClick={async () => {
                await dispatch(setFormOptions({
                    ...window.twoColSignupOptions,
                    salesforceLeadType: 'Enquiry Form',
                    gtmTriggerSubmitEventName: 'enquiry-signup-submit',
                }));

                await dispatch(setSteps([
                    { key: 'step_1', step: 1, fieldGroup: 1, title: 'Hi, nice to meet you', subtitle: 'Our team of retail specialists are on hand to help' },
                    { key: 'step_2', step: 2, fieldGroup: 2, title: 'How should we contact you?' },
                    { key: 'step_3', step: 3, fieldGroup: 3, title: 'Tell us about your business' },
                    { key: 'step_4', step: 4, fieldGroup: 4, title: 'Sign up for your free Vend 14 day trial', buttonText: 'Start your trial' },
                ]));

                await dispatch(setCurrentStep(1));
                await dispatch(show());
            }}
        >
            {window.twoColSignupOptions.buttonText}
        </button>,
        containerEl,
    );
}

export default TwoColumnButtonTrigger;
