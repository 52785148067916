import './styles.scss'

/* global $ */

/*
 * A function for the footer menu collapsing behaviour
 */
const footer = {
  onLoad: () => {
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    if (viewportWidth < 992) {
      $('.vhq-footer-title').on('click', function (e) {
        $(this)
          .parent()
          .next('.vhq-footer-dropdown')
          .toggleClass('open-footer-subnav')
      })
    }
  }
}

export default footer
