import './styles.scss'
import '~templates/components/molecules/marketoForm'
import '~templates/global/modal.scss'
import './react/App.jsx';

/* global $ */

$('[href="#termsModal"]').on('click', function (e) {
  e.preventDefault()
  $('#termsModal').modal('show')
})
