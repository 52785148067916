import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import PhoneInput from './PhoneInput.jsx';
import { useFormContext } from 'react-hook-form';
import { SignupContext } from '../BaseSignupApp.jsx';
import { validateName, validateEmail, validateCountry, validateDomain } from '../helpers/validation';
import { storeNameAsDomain, getCountry, focusField } from '../helpers/helpers';
import { useSelector } from 'react-redux';

const Fields = (props) => {
    const { formOptions } = useSelector((state) => state.signup);
    const { fieldGroup } = props;
    const [country, setCountry] = useState(null);
    const [showDomainValidation, setShowDomainValidation] = useState(false);
    const { register, control, trigger, errors, reset, formState, getValues, setValue, watch, clearErrors } = useFormContext();
    const { dirtyFields, touched, isValidating, submitCount } = formState;

    const countryWatch = watch('country');
    const currencyWatch = watch('currency');
    const domainWatch = watch('domain_prefix');

    const {
        data,
        setData,
        currentStep,
        steps,
        dismissed,
        fields,
        countries,
        manualCountry,
        setManualCountry,
        changeDomain,
        setChangeDomain,
    } = useContext(SignupContext);

    const setAppError = useContext(SignupContext).setError;

    const focusFirstField = () => {
        setTimeout(() => {
            focusField(control, changeDomain, formOptions);
        }, 50);
    }

    useEffect(() => {
        // Focus the domain field
        if (!control.fieldsRef.current['domain_prefix']) {
            return;
        }

        focusFirstField()
    }, [changeDomain]);

    useEffect(() => {
        let defaultValues = {};

        if (currentStep === steps.length) {
            defaultValues = {
                'domain_prefix': data['store_name'] ? storeNameAsDomain(data['store_name']) : '',
                'password': '',
            };
        }

        reset(defaultValues);

        if (!dismissed) {
            focusFirstField();
        }

        updateCountryCurrencyFields(countryWatch);
    }, [currentStep]);

    useEffect(() => {
        let defaultValues = {};

        if (currentStep === steps.length) {
            defaultValues = {
                'domain_prefix': data['store_name'] ? storeNameAsDomain(data['store_name']) : '',
                'password': '',
            };
        }

        reset(defaultValues);

        updateCountryCurrencyFields(countryWatch);
    }, [dismissed]);


    // Update the preview state & currency value if a country value exists
    useEffect(() => {
        if (countryWatch === '') {
            setManualCountry(true);
            return;
        }

        updateCountryCurrencyFields(countryWatch);
    }, [countryWatch]);

    useEffect(() => {
        updateCountryCurrencyFields(data.country);
    }, [data.country]);

    useEffect(() => {
        if (errors['domain_prefix'] === undefined || domainWatch !== undefined) {
            return;
        }

        // It's possible that a delayed fetch domain validation call could happen after going "back"
        // If an domain error occurs when the domain field is gone, clear the error.
        clearErrors('domain_prefix');
    }, [errors['domain_prefix'], domainWatch]);

    const debouncedValidation = useRef(debounce(field => trigger(field.key), 250));

    const filteredFields = fields.filter(field => field.fieldGroup === fieldGroup);

    const updateCountryCurrencyFields = async (countryCode) => {
        // Country field doesn't exist. We don't need to do anything.
        if (!countryCode) {
            return;
        }

        const selectedCountry = getCountry(countries, countryCode);

        // Either the country doesn't exist, we don't know about it or something isn't quite right
        if (!selectedCountry) {
            return;
        }

        // Set country/currency data for use in the preview state
        setCountry(selectedCountry);

        if (countryWatch !== undefined && currencyWatch !== undefined) {
            // Automatically update the currency if the user hasn't already progressed past the currency field step
            if (!data['currency']) {
                // Set the currency field value to the currency code of the currently selected country
                setValue('currency', selectedCountry.currencyCode);
            }

            // Manually trigger validation on the country/currency if they're hidden, show fields if errors
            if (manualCountry === undefined) {
                await trigger(['country', 'currency']);
                setManualCountry(errors['country'] !== undefined);
            }
        }
    }

    const setInitialDomainValidationState = async () => {
        const field = fields.filter(field => field.key === 'domain_prefix')[0];
        const validationMessage = await validateDomain(storeNameAsDomain(getValues('store_name')), field, setAppError);

        setChangeDomain(validationMessage !== true);
    }

    const renderInput = (field) => {
        const registerValidation = register({
            // Standard validation rules
            required: field.validation.required,
            minLength: field.validation.minLength,
            maxLength: field.validation.maxLength,

            // Custom validation rules
            validate: {
                name: value => validateName(value, field),
                email: value => validateEmail(value, field),
                country: value => validateCountry(value, field, countries),
                domain: async value => validateDomain(value, field, setAppError),
            }
        });

        let defaultValue = data[field.key] || field.value;

        if (countries) {
            const countryCode = data['country'] ? data['country'].toUpperCase() : 'US';
            const currencyCode = data['currency'] || countries.filter(country => country.code === countryCode)[0]?.currencyCode;

            if (field.key === 'country') {
                defaultValue = countryCode;
            }

            if (field.key === 'currency') {
                defaultValue = currencyCode;
            }
        }

        if (field.key === 'domain_prefix') {
            defaultValue = storeNameAsDomain(data['store_name']);
        }

        if (field.key === 'phone') {
            return <PhoneInput
                register={registerValidation}
                defaultValue={data['phone_validation']}
                defaultValueWithDialCode={data['phone']}
            />;
        }

        switch (field.type) {
            case 'select':
                return (
                    <select
                        key={field.key}
                        name={field.key}
                        ref={registerValidation}
                        className="form-field vd-input vd-select vd-text--secondary vd-dropdown-input skipped"
                        defaultValue={defaultValue}
                        placeholder={field.placeholder}
                        onChange={() => trigger(field.key)}
                    >
                        <option value="">{field.placeholder}</option>

                        {field.optionGroups ?
                            field.optionGroups.map((optgroup, i) => (
                                <optgroup key={`optgroup_${i}`} label={optgroup.label ? optgroup.label : '-------------'}>
                                    {optgroup.options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label !== undefined ? option.label : option.value}
                                        </option>
                                    ))}
                                </optgroup>
                            ))
                            : null}

                        {field.options ?
                            field.options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label !== undefined ? option.label : option.value}
                                </option>
                            ))
                            : null}
                    </select>
                );

            default:
                return (
                    <input
                        key={field.key}
                        name={field.key}
                        ref={registerValidation}
                        type={field.type}
                        className="form-field grid-12 form-field--border vd-input"
                        defaultValue={defaultValue}
                        placeholder={field.placeholder}
                        onChange={() => {
                            if (field.key === 'domain_prefix') {
                                debouncedValidation.current(field);
                            }
                        }}
                        onBlur={() => {
                            if (field.key === 'store_name') {
                                setInitialDomainValidationState();
                            }
                        }}
                    />
                );
        }
    }

    useEffect(() => {
        setShowDomainValidation(
            submitCount > 0 ||
            changeDomain &&
            !isValidating &&
            (
                dirtyFields['domain_prefix'] ||
                (
                    !dirtyFields['domain_prefix'] &&
                    touched['domain_prefix']
                )
            )
        );
    }, [isValidating, submitCount]);

    return (
        <div className="fields">
            {filteredFields.map((field, i) => {
                if (field.type === 'hidden' || (field.key === 'currency' && country && !manualCountry)) {
                    return (<div key={field.key} className="field is-hidden">{renderInput(field)}</div>);
                }

                if (field.key === 'domain_prefix') {
                    return (
                        <React.Fragment key={field.key}>
                            <div className={`field field--${field.key} ${errors[field.key] && showDomainValidation ? 'is-invalid' : ''} ${showDomainValidation ? 'show-validation' : ''}`}>
                                <label htmlFor={field.key}>
                                    {field.label}
                                    {(!changeDomain || (!errors[field.key] && showDomainValidation)) && (
                                        <i className="fa fa-check-circle" aria-hidden="true" />
                                    )}
                                    {changeDomain === false && <span className="action" onClick={() => setChangeDomain(true)}>Change</span>}
                                </label>

                                {changeDomain === false ? (
                                    <div className="domain-prefix">
                                        <span>{storeNameAsDomain(data['store_name'])}</span>.vendhq.com
                                    </div>
                                ) : (
                                    <>
                                        {renderInput(field)}
                                        <span className="domain-suffix">.vendhq.com</span>
                                    </>
                                )}


                                {errors[field.key] && showDomainValidation && (
                                    <ul>
                                        {errors[field.key].message && <li>{errors[field.key].message}</li>}
                                        {errors[field.key].types && Object.values(errors[field.key].types).map(error => (
                                            <li key={error}>{error}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            {!changeDomain && <div className="field is-hidden">{renderInput(field)}</div>}
                        </React.Fragment>
                    );
                }

                if (field.key === 'country' && country && !manualCountry) {
                    return (
                        <React.Fragment key={field.key}>
                            <div className="field country-preview">
                                {"We think you're in "}{country.name.startsWith('United') ? "the " : ""}
                                <span className={`flag-icon flag-icon-squared flag-icon-${country.code.toLowerCase()}`} />
                                <strong>{country.name}</strong>
                                {", so you'll trade in "}
                                <strong>{country.currencyCode}</strong>.&nbsp;
                                <span className="vd-link" onClick={() => setManualCountry(true)}>Not right?</span>
                            </div>

                            <div className="field is-hidden">{renderInput(field)}</div>
                        </React.Fragment>
                    )
                }

                if (field.key === 'phone') {
                    const validationKey = 'phone_validation';
                    const hasErrors = errors[validationKey] && (dirtyFields[validationKey] || submitCount > 0);

                    return (
                        <div key={field.key} className={`field field--${field.key} ${hasErrors ? 'is-invalid' : ''} show-validation`}>
                            <label htmlFor={field.key}>
                                {field.label}
                            </label>

                            {renderInput(field)}

                            {hasErrors && (
                                <ul>
                                    <li>{errors[validationKey].message}</li>
                                </ul>
                            )}
                        </div>
                    );
                }

                return (
                    <div key={field.key} className={`field field--${field.key} ${errors[field.key] && (dirtyFields[field.key] || submitCount > 0) ? 'is-invalid' : ''} show-validation`}>
                        <label htmlFor={field.key}>
                            {field.label}
                        </label>

                        {renderInput(field)}

                        {errors[field.key] && (dirtyFields[field.key] || submitCount > 0) && (
                            <ul>
                                <li>{errors[field.key].message}</li>
                            </ul>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

Fields.propTypes = {
    fieldGroup: PropTypes.number.isRequired,
};

export default Fields;
