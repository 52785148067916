import React, { Fragment } from 'react'
import reactDOM from 'react-dom'
import Slider from 'react-slick'
import Vimeo from '@u-wave/react-vimeo'
import Modal from 'react-modal'
import "slick-carousel/slick/slick.scss";

const customStyles = {
	content: {
		margin: '188px auto 60px',
		maxWidth: '840px',
		padding: '40px',
		border: 'none',
		borderRadius: '0',
		bottom: 'auto',
		top: 'auto',
		left: '0',
		right: '0',
		position: 'relative',
		overflow: 'visible',
		backgroundColor: 'transparent'
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.7)',
		zIndex: '2000'
	}
};

class Departments extends React.Component {
	constructor(props) {
		super()
		this.state = {
			currentValue: 0,
			currentSlide: 0,
			currentVideo: null,
			showModal: false,
			paused: false,
		}
	}

	changeValue(value) {
		this.setState({
			currentValue: value
		})
	}

	changeSlide(slide) {
		this.setState({
			currentSlide: slide
		})
	}

	setCurrentVideo(videoId) {
		this.setState({
			currentVideo: videoId
		})
	}

	handleOpenModal() {
		this.setState({ showModal: true });
	}

	handleCloseModal() {
		this.setState({ showModal: false });
	}

	handlePause() {
		this.setState({ paused: true })
	}

	render() {
		const { values } = this.props
		const { currentValue, currentSlide, currentVideo, showModal, paused } = this.state

		const { headline, body, colour } = values.length ? values[currentValue] : {
			headline: '',
			body: '',
			color: ''
		}

		const thumbnails = values.map(value => ({
			title: value.title,
			url: value.image
		}))

		const videos = values.map(value => ({
			title: value.title,
			imageUrl: value.imageLarge,
			id: value.videoId
		}))

		const NextArrow = (props) => {
			const { className, onClick } = props;
			return (
				<div className={`c-slick-arrow c-slick-arrow--next ${className}`} onClick={onClick}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 25" width="15" height="25">
						<path d="M2.664 24.8698c-.6728 0-1.3193-.2604-1.8366-.7548-1.0082-1.0152-1.0082-2.6826 0-3.6977l7.8365-7.9171L.8023 4.584C-.2059 3.5688-.2059 1.9015.8023.8864 1.8106-.129 3.4664-.129 4.4745.8864l9.6983 9.7645c.491.4944.7496 1.1455.7496 1.8494 0 .703-.285 1.354-.7496 1.8494l-9.673 9.7656c-.5172.4944-1.189.7548-1.8356.7548l-.0001-.0003z" fill="#B8B8B8" fillRule="nonzero" />
					</svg>
				</div>
			)
		};

		const PrevArrow = (props) => {
			const { className, onClick } = props;
			return (
				<div className={`c-slick-arrow c-slick-arrow--prev ${className}`} onClick={onClick}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 25" width="15" height="25">
						<path d="M12.3045.1253c.6728 0 1.3194.2604 1.8366.7548 1.0082 1.0152 1.0082 2.6825 0 3.6976L6.3047 12.495l7.8615 7.9161c1.0082 1.0152 1.0082 2.6826 0 3.6977-1.0082 1.0152-2.664 1.0152-3.6721 0L.7958 14.3441c-.491-.4944-.7496-1.1454-.7496-1.8494 0-.7029.2849-1.354.7496-1.8493l9.673-9.7657c.5171-.4943 1.189-.7548 1.8355-.7548l.0002.0004z" fill="#B8B8B8" fillRule="nonzero" />
					</svg>
				</div>
			)
		};

		const sliderParams = {
			slidesToShow: 3,
			slidesToScroll: 3,
			infinite: false,
			initialSlide: currentSlide,
			afterChange: index => {
				this.changeSlide(index)
				this.setCurrentVideo(null)
			},
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />
		}

		const Thumbnails = () => (
			<div className="c-department-videos__thumbnails d-none d-lg-block">
				<Slider {...sliderParams}>
					{
						values.map((value, index) => {
							return (
								<div
									className={`c-department-videos__image-stack${index === currentValue ? ' active' : ''}`}
									onClick={() => {
										this.changeValue(index)
										this.setCurrentVideo(null)
									}}
									key={`department${index}`}
								>
									<img
										src={value.image}
										alt={value.title}
										className="c-department-videos__thumbnail"
										key={`image-${index}`}
									/>
								</div>
							)
						})
					}
				</Slider>
			</div>
		)

		return (
			<div className="c-department-videos">
				<div className="vhq-careers__values__nav vhq-careers__values__nav--hrz d-none d-lg-flex">
					{values.map((value, i) => (
						<span
							className={`vhq-careers__values__nav-item ${i === currentValue ? `vhq-careers__values__nav-item--active text-${value.colour}` : 'text-gray-dark'}`}
							onClick={() => {
								this.changeValue(i)
								this.changeSlide(i)
							}} key={`nav-item-${i}`}>
							{
								value.headline && value.headline
							}
						</span>
					))}
				</div>
				<div className="c-department-videos__item row gutter-xs-none align-items-center">
					<div className="c-department-videos__imagery col col-lg-6 col-xl-7">
						<div className="vhq-careers__imagestack vhq-careers__imagestack--large" style={{ overflow: 'hidden' }}>
							<Modal isOpen={showModal} style={customStyles}>
								<button className="c-department-videos__close" onClick={() => {
									this.handleCloseModal()
									this.handlePause()
								}}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26" width="25" height="26">
										<path d="M22.753.6256l2.1214 2.1214-10.2538 10.2526L24.8744 23.253l-2.1214 2.1214-10.2534-10.2538L2.247 25.3744.1256 23.253l10.253-10.2534L.1256 2.747 2.247.6256l10.2526 10.253L22.753.6256z" fill="#FFF" fillRule="evenodd" />
									</svg>
								</button>
								<div className="embed-responsive embed-responsive-16by9">
									<Vimeo
										className="embed-responsive-item"
										video={currentVideo}
										autoplay
										paused={paused} />
								</div>
							</Modal>

							{videos.map((video, i) => {
								// Return styled images
								return (
									<Fragment
										key={`image-${i}`}>
										<div className={`vhq-careers__imagestack__image${currentValue === i ? ' vhq-careers__imagestack__image--active' : ''}`
										} onClick={() => {
											this.setCurrentVideo(`https://vimeo.com/${video.id}`)
											this.handleOpenModal()
										}}>
											<img
												src={video.imageUrl}
												alt={video.title}
											/>
											<div className="c-play-button c-play-button--centered">
												<div className="c-play-button__icon">
													<svg width="32" height="41" viewBox="0 0 32 41" xmlns="http://www.w3.org/2000/svg">
														<path d="M2.726 40.3694l27.9893-18.1455c1.1652-.7559 1.1659-2.676.0013-3.4326L2.7274.6086C1.577-.139.151.8117.151 2.3242v36.3282c0 1.5124 1.4248 2.4624 2.575 1.717" fill="#5F7887" fillRule="nonzero" />
													</svg>
												</div>
											</div>
										</div>
									</Fragment>
								)
							})}
						</div>
						<Thumbnails />
					</div>
					<div className={`vhq-careers__values__content col col-12 col-lg-6 col-xl-5 ${colour && colour}-light d-none d-lg-block`}>
						{
							headline && <h3>{headline}</h3>
						}
						{
							body && <p>{body}</p>
						}
					</div>
				</div>
				<div className="vhq-careers__values__nav vhq-careers__values__nav--mobile vhq-careers__values__nav--hrz d-lg-none">
					{values.map((value, i) => (
						<span
							className={`vhq-careers__values__nav-item ${i === currentValue ? `vhq-careers__values__nav-item--active text-${value.colour}` : 'text-gray-dark'}`}
							onClick={() => {
								this.changeValue(i)
								this.changeSlide(i)
							}} key={`nav-item-${i}`}>
							{value.headline && value.headline}
						</span>
					))}
				</div>
			</div >
		)
	}
}

const elem = document.getElementById('videos-app')
if (elem) {
	const values = JSON.parse(elem.getAttribute('data-values'))

	Modal.setAppElement(elem)

	reactDOM.render(<Departments values={values} />, elem)
}