import './styles.scss'
import '../../atoms/card'
import '../../atoms/section'

/* global $ */

const replaceVideo = jqSelector => {
  // Pause the carousel if applicable
  jqSelector.parents('.carousel').carousel('pause')
  // Grab the video ID
  const videoID = jqSelector.data('video-id')

  if (videoID)
    jqSelector
    .children('.vhq-placeholder')
    .replaceWith(
      '<div class="embed-responsive embed-responsive-16by9" style="position: absolute; top: 0; height: 100%; width: 100%; max-width: none;"><iframe class="embed-responsive-item width="540" height="303" src="https://www.youtube.com/embed/' +
      videoID +
      '?modestbranding=1&autoplay=1&controls=1&loop=1&playsinline=1&rel=0" frameborder="0" allowfullscreen></iframe></div>'
    )
}

$('.js-video-placeholder').on('click', function (event) {
  // Replace video
  replaceVideo($(this))
})

$('.js-video-anchor-link').on('click', function (event) {
  // Grab the video Selector
  const videoSelector = $(this).data('video-selector')
  // Replace video
  replaceVideo($(videoSelector))
})
