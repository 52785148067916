import React from 'react'
import classNames from 'classnames'

const ImageStack = ({ images, activeValue, large, onClick }) => {

	// Set wrapper classes
	const wrapperClasses = classNames("vhq-careers__imagestack", {
		"vhq-careers__imagestack--large": large
	})

	return (
		<div className={wrapperClasses} onClick={onClick}>
			{images.map((image, i) => {
				// Set image classes
				const imageClasses = classNames("vhq-careers__imagestack__image", {
					"vhq-careers__imagestack__image--active": activeValue === i
				})
				// Return styled images
				return (
					<img
						src={image.url}
						alt={image.title}
						className={imageClasses}
						key={`${image.url}${i}`}
					/>
				)
			})}
		</div>
	)
}

export default ImageStack