import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fields from '../components/Fields.jsx';
import { SignupContext } from '../BaseSignupApp.jsx';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const Step = (props) => {
    const { formOptions } = useSelector((state) => state.signup);
    const { step, title, subtitle, buttonText, fieldGroup } = props;

    const { formState, errors } = useFormContext();
    const { submitCount, isValid } = formState;

    const {
        currentStep,
        steps,
        countries,
        nextStep,
        prevStep,
        submitted,
    } = useContext(SignupContext);

    const [loading, setLoading] = useState(false);
    const [disableContinue, setDisableContinue] = useState(false);

    useEffect(() => {
        setDisableContinue(Object.keys(errors).length > 0 && submitted);
    }, [errors, submitCount, isValid]);

    useEffect(() => {
        if (!submitted || Object.keys(errors).length > 0) {
            return;
        }

        if (countries.length === 0 && currentStep === 2) {
            setLoading(true);
            setDisableContinue(true);
            return;
        }

        nextStep();
    }, [submitCount]);

    useEffect(() => {
        if (!submitted || Object.keys(errors).length > 0 || !loading || countries.length === 0) {
            return;
        }

        nextStep();
    }, [countries]);

    return (
        <>
            <div className="heading">
                {currentStep === steps.length && formOptions.fileUrl && (
                    <div className="signup-download-file">
                        <p>
                            Thanks! Your download is ready:
                            <a href={formOptions.fileUrl} target="_blank" rel="noopener noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="download"
                                    className="svg-inline--fa fa-download fa-w-16" role="img" viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" />
                                </svg>
                                {formOptions.downloadButtonText ? formOptions.downloadButtonText : 'Download'}
                            </a>
                        </p>
                        <h2>{`While we've got you why not...`}</h2>
                    </div>
                )}
                {currentStep === steps.length && formOptions.fileEmail && !formOptions.fileUrl && (
                    <div className="signup-download-file">
                        <p>
                            Thanks! Check your inbox to download the guide.
                        </p>
                        <h2>{`While we've got you why not...`}</h2>
                    </div>
                )}
                {currentStep === steps.length && formOptions.enquirySuccessMessage && (
                    <div className="signup-enquiry-success">
                        <p>
                            {formOptions.enquirySuccessMessage}
                        </p>
                        <h2>{`While we've got you why not...`}</h2>
                    </div>
                )}
                {currentStep <= 2 && formOptions.newsletterSuccessMessage && (
                    <div className="signup-enquiry-success">
                        <p>
                            {formOptions.newsletterSuccessMessage.split('\n').map((str, i) => (<React.Fragment key={i}>{str}<br /></React.Fragment>))}
                        </p>
                        <h2>{`While we've got you why not...`}</h2>
                    </div>
                )}
                <h1>{title}</h1>
                {subtitle && <p className="subtitle">{subtitle}</p>}
            </div>

            {fieldGroup && <Fields fieldGroup={fieldGroup} />}

            <div className={'actions' + (step > 1 ? ' spaced' : '')}>
                {step > 1 &&
                    <button
                        type="button"
                        className="back"
                        onClick={prevStep}
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true" />
                        {'Back'}
                    </button>
                }

                <button type="submit" className={`continue btn btn-primary ${disableContinue && 'disabled'}`}>
                    {loading ? <div className="signup-loader__spinner signup-loader__spinner--white" /> : buttonText}
                </button>
            </div>
        </>
    );
};

Step.defaultProps = {
    title: '',
    subtitle: '',
    buttonText: 'Continue',
    fieldGroup: null,
};

Step.propTypes = {
    step: PropTypes.number.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonText: PropTypes.string,
    fieldGroup: PropTypes.number,
};

export default Step;
