import './styles.scss'
import './react/App.jsx';
var sr = require('~templates/global/vendor/scrollreveal.min.js')()

/* global $ */

const revealRiverRow = riverId => {
  sr.reveal('.js-river[data-river-id="' + riverId + '"]', {
    duration: 1000,
    opacity: 0,
    scale: 1,
    afterReveal: function (riverRow) {
      // Window width
      let windowWidth = $(window).width()

      // Lazy load the animation images
      $(riverRow).find('img.js-lazy').each(function () {
        $(this).attr('src', $(this).data('src'))
        $(this).removeClass('d-none')
      })

      // start animation if second image exists and window size is tablet or larger
      if (
        $(riverRow).find('.vhq-river-images-2').length &&
        windowWidth >= 768
      ) {
        // Start animation loop on the second image
        triggerAnimations(2, riverRow)
      }
    }
  })
}

/**
 *
 * @param {DOM element} element - Image to activate next
 * @param {DOM element} river - Parent river row element
 */
const triggerAnimations = (imageNumber, riverRow) => {
  setTimeout(() => {
    // Get current element
    var element = $(riverRow).find('.vhq-river-images-' + imageNumber)

    // Remove all previous active states in this river row
    $(riverRow)
      .find('.vhq-river-images--active')
      .removeClass('vhq-river-images--active')

    if (element.length) {
      // Make element active
      element.addClass('vhq-river-images--active')
    } else {
      // Loop back to first element
      imageNumber = 1
    }

    // Trigger animations on the next image
    triggerAnimations(imageNumber + 1, riverRow)
  }, 4000)
}

document.addEventListener('DOMContentLoaded', () => {
  $('.js-river').each(function () {
    let riverId = $(this).data('river-id')
    revealRiverRow(riverId)
  })
})
