import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { show, setCurrentStep, setSteps, setFormOptions, setFormData, setDismissable, setModalMode } from '../../../components/organisms/signupApp/store/signupSlice';

const IsolatedSignupApp = () => {
    const [initialised, setInitialised] = useState(false);
    const dispatch = useDispatch();

    const containerEl = document.getElementById('isolated-signup-app');

    if (!initialised && containerEl) {
        window.addEventListener('message', async (e) => {
            const payload = e.data;

            if (payload.step != null) {
                await dispatch(setCurrentStep(payload.step));
            }

            if (payload.formData != null) {
                await dispatch(setFormData(payload.formData));
            }
        });
    }

    const initialise = async () => {
        await dispatch(setFormOptions({
            ...window.navigationSignupOptions,
            salesforceLeadType: 'Trial Intent',
            gtmTriggerSubmitEventName: 'progressive-trial-signup-submit',
            newsletterSuccessMessage: 'Thanks for signing up to the Vend newsletter.\nYou will receive a confirmation email shortly.'
        }));

        await dispatch(setSteps([
            { key: 'step_1', step: 1, fieldGroup: 1, title: 'Start your free 14 day trial of Vend' },
            { key: 'step_2', step: 2, fieldGroup: 2, title: 'Start your free 14 day trial of Vend', subtitle: 'How should we contact you?' },
            { key: 'step_3', step: 3, fieldGroup: 3, title: 'Start your free 14 day trial of Vend', subtitle: 'Tell us about your business' },
            { key: 'step_4', step: 4, fieldGroup: 4, title: 'Sign up for your free Vend 14 day trial', buttonText: 'Start your trial' },
        ]));

        await dispatch(setModalMode());
        await dispatch(setDismissable(true));
        await dispatch(show());

        setInitialised(true);
    }

    useEffect(() => {
        if (initialised || !containerEl) {
            return;
        }

        initialise();
    });

    return <React.Fragment />
}
export default IsolatedSignupApp;