import "./styles.scss";
import Modal from 'modal-vanilla';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

window.Modals = [];

document.addEventListener('DOMContentLoaded', () => {
  const modals = Array.from(document.querySelectorAll('.js-modal'));

  if (modals.length) {
    modals.forEach(modal => {
      const canClose = modal.dataset.closable === 'true';
      const showOnLoad = modal.dataset.showOnLoad === 'true';
      const coverMain = modal.dataset.coverMain === 'true';
      const dontShow = modal.dataset.dontShow;

      const thisModal = new Modal({
        el: modal,
        transition: 500,
        keyboard: canClose,
        backdrop: canClose ? true : 'static',
        appendTo: coverMain ? '#content' : 'body',
      });

      if (coverMain) {
        thisModal.on('show', () => {
          document.body.classList.add('modal-open--main-only');
          window.Modal = thisModal;
        });

        thisModal.on('hidden', () => {
          document.body.classList.remove('modal-open--main-only');
          window.Modal = null;
        });
      }

      window.Modals.push(thisModal);

      if (showOnLoad) {
        if (dontShow && getParameterByName(dontShow) !== 'true') {
          thisModal.show();
        }
      } else {
        const id = modal.id;
        const modalTriggers = Array.from(document.querySelectorAll(`[data-modal=${id}]`));

        modalTriggers.forEach(trigger => {
          trigger.addEventListener('click', function (e) {
            e.preventDefault();
            const videoId = trigger.dataset.videoId;

            thisModal.show();

            if (videoId) {
              const videoService = trigger.dataset.videoService;
              const demoVideo = document.getElementById('DemoSnippets');

              let videoSrc = `https://www.youtube-nocookie.com/embed/${videoId}?modestbranding=1&autoplay=1&controls=1&loop=1&playsinline=1&rel=0`;
              if (videoService === 'vimeo') {
                videoSrc = `https://player.vimeo.com/video/${videoId}?autoplay=1&title=0&byline=0&portrait=0`;
              }

              if (demoVideo) {
                demoVideo.src = videoSrc;

                thisModal.on('hidden', () => {
                  demoVideo.src = '';
                });
              }
            }
          });
        });
      }
    });
  }
})
