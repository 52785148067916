import React from 'react'
import reactDOM from 'react-dom'
import ImageStack from './components/ImageStack.jsx'

class Values extends React.Component {
	constructor(props) {
		super()
		this.state = {
			currentValue: 0
		}
	}

	changeValue(value) {
		this.setState({ currentValue: value })
	}

	render() {
		const { values } = this.props
		const { currentValue } = this.state

		const { headline, body, colour } = values[currentValue]

		var thumbIndexes = []
		for (let i in values)
			if (i != currentValue)
				thumbIndexes.push(parseInt(i))

		var thumbnails = values.map(value => ({
			title: value.title,
			url: value.image
		}))

		var images = values.map(value => ({
			title: value.title,
			url: value.imageLarge
		}))

		const Thumbnails = () => (
			<div className="vhq-careers__values__thumbnails">
				<ImageStack onClick={() => this.changeValue(thumbIndexes[0])}
					images={thumbnails} activeValue={thumbIndexes[0]} large={false} />
				<ImageStack onClick={() => this.changeValue(thumbIndexes[1])}
					images={thumbnails} activeValue={thumbIndexes[1]} large={false} />
				<ImageStack onClick={() => this.changeValue(thumbIndexes[2])}
					images={thumbnails} activeValue={thumbIndexes[2]} large={false} />
			</div>
		)

		return (
			<div className="position-relative">
				<div className="d-lg-none vhq-careers__values__mobile">
					<div className="vhq-careers__values__mobile__image">
						<ImageStack images={thumbnails} activeValue={currentValue} large={true} />
					</div>
					<Thumbnails />
				</div>
				<div className="row gutter-xs-none align-items-center">
					<div className="col-lg-6 col-xl-7 d-none d-lg-flex">
						<ImageStack images={images} activeValue={currentValue} large={true} />
						<Thumbnails />
					</div>
					<div className={`col-12 col-lg-6 col-xl-5 vhq-careers__values__content bg-${colour}`}>
						<h3>{headline}</h3>
						<p>{body}</p>
					</div>
				</div>
				<div className="row d-none d-lg-block">
					<div className="col-6 vhq-careers__values__nav">
						{values.map((value, i) => (
							<span
								className={`vhq-careers__values__nav-item ${i === currentValue ? `vhq-careers__values__nav-item--active text-${value.colour}` : 'text-gray-dark'}`}
								onClick={() => this.changeValue(i)}
								key={`nav-item-${i}`}>
								{value.headline}
							</span>
						))}
					</div>
				</div>
			</div>
		)
	}
}

var elems = Array.from(document.querySelectorAll('.js-values-app'));

if (elems.length) {
	elems.forEach(elem => {
		var values = JSON.parse(elem.getAttribute('data-values'))

		reactDOM.render(<Values values={values} />, elem)
	})
}
