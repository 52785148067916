import Cookies from "js-cookie";

export default async (currentStep, formData, countries, executeRecaptcha, error, setError, formOptions) => {
    if (currentStep < 2) {
        return;
    }

    const recaptchaToken = await executeRecaptcha('crafty_progressive_signup');

    const lead = {...formData};
    delete lead.password;

    if (!lead.first_name || !lead.last_name || !lead.email || !lead.phone) {
        return;
    }

    const data = {
        lead,
        type: formOptions.salesforceLeadType,
        'g-recaptcha-response': recaptchaToken,
        [window.vhqSiteConfig.csrfTokenName]: window.vhqSiteConfig.csrfToken,
        campaign: Cookies.get('campaign'),
        marketo: Cookies.get('_mkto_trk'),
        error: error ? `Lead encountered an error during signup: ${error.errorDescription}` : null,
    };

    const countryCode = formData['country'] || 'US';
    const country = countries.filter(country => country.code === countryCode)[0];

    if (country) {
        data.lead.country = country.name;
    }

    if (formOptions.contentType) {
        data.contentType = formOptions.contentType;
    }

    const gclid = window.localStorage.gclid;

    if (gclid != null) {
        data.gclid = gclid;
    }

    const gaidCookie = Cookies.get('_ga'); // e.g. GA1.2.1992550010.1627277872

    if (gaidCookie != null) {
        const gaidParts = gaidCookie.split('.').slice(-2);

        if (gaidParts.length === 2) {
            data.gaid = gaidParts.join('.');
        }
    }

    try {
        const response = await fetch('/actions/salesforce/process-lead', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();

        if (!result.success) {
            throw new Error(result.errors[0])
        }
    } catch (e) {
        console.error(`Failed to 'process-lead'`, e.message);

        if (formOptions.showSignupSalesforceErrors) {
            setError({
                returnStep: 1,
                message: `
                        Sorry - something's not working.
                        You might be able to try again, or if this keeps happening please contact us for assistance.
                    `,
                errorDescription: e.message,
            });
        }

        // send error to Raygun
        if (window.rg4js) {
            window.rg4js('send', {
                error: new Error(`Failed to 'process-lead' on signup`),
                customData: data,
            });
        }
    }
}
