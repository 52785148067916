import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { SignupContext } from '../../../../components/organisms/signupApp/BaseSignupApp.jsx';
import { focusField } from '../../../../components/organisms/signupApp/helpers/helpers';
import { setMediaMode, setFormOptions } from '../../../../components/organisms/signupApp/store/signupSlice';

const Video = (props) => {
    const dispatch = useDispatch();
    const { formOptions } = useSelector((state) => state.signup);
    const { changeDomain } = useContext(SignupContext);
    const { control } = useFormContext();
    const [isPaused, setIsPaused] = useState(true);
    const { closed, vimeoId, handlePlayerEnd } = props;

    const setMini = async (isMediaMode) => {
        await dispatch(setMediaMode(isMediaMode));
    }

    const setOpenInNewTab = async (openInNewTab) => {
        await dispatch(setFormOptions({
            ...formOptions,
            signupNewTab: openInNewTab,
        }));
    }

    useEffect(() => {
        if (closed) {
            setOpenInNewTab(false);
            setMini(false);

            return;
        }

        setOpenInNewTab(true);
        setMini(true);
        focusField(control, changeDomain);
    }, [closed]);

    if (!vimeoId) {
        return null;
    }

    return (
        <div className={`video-container ${closed ? 'is-hidden' : ''}`}>
            <Vimeo
                className="video-player"
                video={`https://player.vimeo.com/video/${vimeoId}`}
                showByline={false}
                responsive={true}
                autoplay={true}
                showTitle={false}
                showPortrait={false}
                transparent={false}
                color="41AF4B"
                onEnd={handlePlayerEnd}
            />
        </div>
    );
};

Video.propTypes = {
    vimeoId: PropTypes.string.isRequired,
    closed: PropTypes.bool.isRequired,
};

export default Video;
