import Cookies from 'js-cookie';
import './styles.scss'
import tippy, { roundArrow } from 'tippy.js';

/* global $ */

document.addEventListener('DOMContentLoaded', () => {
  var tippyInstances = tippy('.js-phone-reveal', {
    theme: 'light-border',
    arrow: roundArrow,
    allowHTML: true,
  });

  function toggleHiddenPhoneNumber() {
    document.querySelectorAll('.js-phone-reveal').forEach(el => {
      if (!Cookies.get('phoneVisible')) {
        el.innerText = el.innerText.substring(0, el.innerText.length - 3) + '...';

        tippyInstances.forEach(instance => {
          instance.enable();
        });
      } else {
        el.innerText = el.dataset.phone;

        tippyInstances.forEach(instance => {
          instance.disable();
        });
      }
    });
  }

  document.querySelectorAll('.js-phone-reveal').forEach(el => {
    toggleHiddenPhoneNumber();

    el.addEventListener('click', () => {
      console.log('fodfs');

      Cookies.set('phoneVisible', 'true', {
        expires: new Date(new Date().getTime() + 30 * 60 * 1000), // 30 mins
      })

      toggleHiddenPhoneNumber();

      window.analytics.track("Revealed Sales Phone Number", {
        page_path: window.location.href
      });
    });
  });
})
