import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { show, setSteps, setFormOptions, setModalMode, setDismissable, setCurrentStep } from '../../../components/organisms/signupApp/store/signupSlice';

import Logo from './components/Logo.jsx';
import Video from './components/Video.jsx';

const MultiVideoSignupApp = () => {
    const dispatch = useDispatch();
    const { steps, currentStep, formOptions } = useSelector((state) => state.signup);
    const [initialised, setInitialised] = useState(false);
    const [videoClosed, setVideoClosed] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(0);

    const initialise = async () => {
        await dispatch(setFormOptions({
            ...window.multiVideoSignupOptions,
            salesforceLeadType: 'Multi Video Intent',
            gtmTriggerSubmitEventName: 'multi-signup-submit',
        }));

        await dispatch(setSteps([
            { key: 'step_1', step: 1, fieldGroup: 1, title: 'Get access to exclusive video content', subtitle: 'Plus heaps more great online resources' },
            { key: 'step_2', step: 2, fieldGroup: 2, title: 'Get access to exclusive video content', subtitle: 'Plus heaps more great online resources' },
            { key: 'step_3', step: 3, fieldGroup: 3, title: 'Get access to exclusive video content', subtitle: 'Plus heaps more great online resources', buttonText: videoClosed ? 'Continue' : 'Watch Video' },
            { key: 'step_4', step: 4, fieldGroup: 4, title: 'Almost there!', subtitle: 'Sign up now for your 14 day trial', buttonText: 'Start your trial' },
        ]));

        await dispatch(setCurrentStep(1));
        await dispatch(show());
        await dispatch(setModalMode());
        await dispatch(setDismissable(false));

        setInitialised(true);
    }

    const containerEl = document.getElementById('multi-video-signup-app');

    useEffect(() => {
        if (initialised || !containerEl) {
            return;
        }

        initialise();
    });

    useEffect(() => {
        const signupContainer = document.querySelector('.signup-container');

        if (signupContainer == null) {
            return;
        }

        signupContainer.scrollTo(0, 0);
    }, [selectedVideo])

    useEffect(() => {
        if (Array.isArray(steps)) {
            setVideoClosed(currentStep !== steps.length);
        }
    }, [currentStep]);

    if (!containerEl || !formOptions.videos) {
        return null;
    }

    const handlePlayerEnd = () => {
        if (selectedVideo < (formOptions.videos.length - 1)) {
            setSelectedVideo(selectedVideo + 1);
        }
    }

    if (currentStep !== 4) {
        return <Logo />;
    }

    return (
        <div className="videos">
            <div className="video-wrap">
                <Video vimeoId={formOptions.videos[selectedVideo].vimeoId} closed={videoClosed} handlePlayerEnd={handlePlayerEnd} />
            </div>

            <div className="video-list">
                <h3>Take a look at our other great videos</h3>

                <div className="video-list-items">
                    {formOptions.videos.map((video, i) => {
                        return (
                            <div key={video.key} className={`video-list-item ${selectedVideo === i ? 'is-active' : ''}`} onClick={() => setSelectedVideo(i)}>
                                <div className="video-list-item__thumb">
                                    <svg width="125" height="124" viewBox="0 0 125 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.6" d="M62.8181 108.126H61.2299C60.9001 108.061 60.5668 108.014 60.2317 107.987C49.913 107.676 40.6994 104.232 32.8719 97.6005C19.1342 85.9615 13.6368 70.96 16.795 53.3222C21.4075 27.5619 46.8887 11.1357 72.3812 16.9575C90.8381 21.1729 104.932 36.3604 107.652 55.0079C107.956 57.0839 108.086 59.1848 108.297 61.2767V63.0917C108.238 63.6113 108.151 64.1309 108.127 64.6527C107.967 68.9482 107.12 73.1903 105.617 77.2174C99.1949 94.0324 87.1286 104.114 69.4181 107.463C67.2605 107.867 65.0211 107.912 62.8181 108.126Z" fill="white" />
                                        <path d="M45.2009 63.7C45.2009 68.9433 45.2009 74.1865 45.2123 79.4298C45.1999 80.2591 45.276 81.0874 45.4392 81.9006C46.0154 84.5097 48.2979 85.719 50.7936 84.7661C51.425 84.5178 52.0364 84.2213 52.6223 83.879C61.9108 78.5699 71.1948 73.2518 80.4743 67.9246C81.1611 67.5327 81.796 67.0563 82.3643 66.5066C84.0886 64.814 84.0455 62.5702 82.2418 60.9457C81.5578 60.355 80.8141 59.8372 80.0228 59.4006C70.9475 54.1702 61.8639 48.9565 52.772 43.7594C51.8601 43.2344 50.8797 42.8389 49.8588 42.5841C48.1005 42.1621 46.4783 42.9811 45.8249 44.676C45.4421 45.6918 45.2402 46.7668 45.2281 47.8523C45.1692 53.1387 45.2009 58.4205 45.2009 63.7Z" fill="#4E5C65" />
                                    </svg>

                                    <img src={video.thumbnail} alt={video.title} />
                                </div>
                                <div className="video-list-item__details">
                                    <h5 className="video-list-item__title">{video.title}</h5>
                                    <p>{video.subtitle}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
export default MultiVideoSignupApp;