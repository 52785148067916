import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { store } from './store';

import BaseSignupApp from './BaseSignupApp.jsx';

import TwoColumnButtonTrigger from '../../../refresh/components/body/TwoColumn/react/App.jsx';
import DiagonalHeaderSignupMiniSignupForm from '../diagonalHeader/react/App.jsx';
import ClosingFormBlockSignupForm from '../closingFormBlock/react/App.jsx';
import HeroBannerSignupForm from '../heroBannerForm/react/App.jsx';
import HeroTwoColSignupApp from '../heroTwoCol/react/App.jsx';
import RiverRowSignupButton from '../../molecules/riverRow/react/App.jsx';
import TrialSignupApp from '../../../singles/signup-progressive/react/App.jsx'
import DemoVideoSignupApp from '../../../singles/signup-demo-video/react/App.jsx'
import IsolatedSignupApp from '../../../singles/signup-isolated/react/App.jsx'
import MultiVideoSignupApp from '../../../channels/gatedVideoPages/react/App.jsx';

const BaseSignupAppWrapper = (props) => {
    const [isReady, setIsReady] = useState(false);

    if (!isReady) {
        if (window.vhqSiteConfig && window.vhqSiteConfig.loaded) {
            setIsReady(true);
        } else {
            document.addEventListener('vhqSiteConfigLoaded', () => {
                setIsReady(true);
            });
        }

        return null;
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={window.vhqSiteConfig.recaptchaV3SiteKey}>
            <Provider store={store}>
                <BaseSignupApp
                    steps={[]}
                >
                    <DemoVideoSignupApp />
                    <MultiVideoSignupApp />
                </BaseSignupApp>
                <TwoColumnButtonTrigger />
                <DiagonalHeaderSignupMiniSignupForm />
                <ClosingFormBlockSignupForm />
                <HeroBannerSignupForm />
                <HeroTwoColSignupApp />
                <RiverRowSignupButton />
                <TrialSignupApp />
                <IsolatedSignupApp />
            </Provider>
        </GoogleReCaptchaProvider>
    );
};

if (document.getElementById('global-signup-app')) {
    render(
        <BaseSignupAppWrapper />,
        document.getElementById('global-signup-app'),
    );
}
