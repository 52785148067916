import React, { useEffect, useRef, useState } from 'react';
import { matchSorter } from 'match-sorter';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from '../../signupApp/store/signupSlice';
import phoneCodesJSON from '../data/phoneCountryDialCodes.json';

const PhoneInput = (props) => {
    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.signup);

    const data = formData;
    const setData = async (newData) => {
        await dispatch(setFormData({ ...data, ...newData }));
    }

    const phoneCountryOptions = phoneCodesJSON
        .map(country => ({ ...country, isoCode: country.isoCode.toLowerCase() }))
        .sort((a, b) => (a.featured === b.featured) ? 0 : a.featured ? -1 : 1);

    const debug = false;
    const [dirty, setDirty] = useState(false);
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [phoneValue, setPhoneValue] = useState(props.defaultValue);
    const [items, setItems] = useState(phoneCountryOptions);
    const searchBoxRef = useRef();
    const searchRef = useRef();
    const inputRef = useRef();

    const { setValue } = useFormContext();

    useEffect(() => {
        if (!selectedCountry) {
            return;
        }

        let formattedPhoneNumber = phoneValue;

        formattedPhoneNumber = formattedPhoneNumber.replaceAll(' ', '');
        formattedPhoneNumber = formattedPhoneNumber.replaceAll('(', '');
        formattedPhoneNumber = formattedPhoneNumber.replaceAll(')', '');
        formattedPhoneNumber = formattedPhoneNumber.replaceAll('-', '');

        if (formattedPhoneNumber.charAt(0) === '0') {
            formattedPhoneNumber = formattedPhoneNumber.substring(1);
        }

        setValue('phone_validation', formattedPhoneNumber, { shouldValidate: true, shouldDirty: dirty });

        formattedPhoneNumber = `${selectedCountry.dialCode}${formattedPhoneNumber}`;
        setValue('phone', formattedPhoneNumber, { shouldValidate: false });

        props.onChange({
            dialCode: selectedCountry.dialCode,
            phoneNumber: phoneValue,
        });
    }, [phoneValue, selectedCountry]);

    useEffect(() => {
        if (selectedCountry == null) {
            return;
        }

        setOptionsVisible(false);
    }, [selectedCountry]);

    useEffect(() => {
        if (!optionsVisible) {
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
            }

            if (searchRef && searchRef.current) {
                searchRef.current.value = '';
            }
        } else {
            if (searchRef && searchRef.current) {
                searchRef.current.focus();
            }
        }
    }, [optionsVisible]);

    const onCountrySelect = (country) => {
        setData({
            ...data,
            country: country.isoCode.toUpperCase(),
        });
    };

    const onNumberChange = () => {
        const value = inputRef.current.value;
        setPhoneValue(value);
    };


    useEffect(() => {
        const defaultCountryCode = data.country ? data.country.toLowerCase() : 'us';

        setSelectedCountry(phoneCountryOptions.find(country => props.defaultValueWithDialCode
            ? props.defaultValueWithDialCode.includes(country.dialCode)
            : country.isoCode === defaultCountryCode
        ));
    }, [data.country]);

    const getItems = value => {
        return value
            ? matchSorter(phoneCountryOptions, value, {
                keys: ['name', 'isoCode', 'dialCode'],
            })
            : phoneCountryOptions
    };

    if (!selectedCountry) {
        return null;
    }

    return (
        <div>
            <div className={`phone-input ${optionsVisible && 'menu-open'}`}>
                <div role="button"
                    tabIndex={0}
                    className="phone-search-trigger vd-input"
                    onClick={() => setOptionsVisible(true)}
                    onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                            return;
                        }

                        e.preventDefault();
                        setOptionsVisible(true);
                    }}
                >
                    <div className={`flag-icon flag-icon-squared flag-icon-${selectedCountry.isoCode}`} />
                    {selectedCountry.dialCode}
                </div>

                <input ref={inputRef} className="form-field grid-12 form-field--border vd-input" value={phoneValue} onBlur={() => setDirty(true)} onChange={onNumberChange} />

                <div ref={searchBoxRef} className={`phone-search ${optionsVisible && 'is-visible'}`}>
                    <Downshift isOpen={optionsVisible}
                        initialSelectedItem={selectedCountry}
                        initialInputValue=""
                        onOuterClick={() => setOptionsVisible(false)}
                        onStateChange={(changes, downshiftState) => {
                            if (changes.hasOwnProperty('inputValue')) {
                                setItems(getItems(changes.inputValue));
                            }
                        }}
                        onChange={(selectedItem, downshiftState) => {
                            setItems(phoneCountryOptions);
                            onCountrySelect(selectedItem);
                        }}
                        itemToString={item => (item ? item.name : '')}
                    >
                        {({
                            getInputProps,
                            getItemProps,
                            getMenuProps,
                            highlightedIndex,
                            selectedItem,
                            getRootProps,
                            selectHighlightedItem,
                        }) => (
                            <div {...getRootProps({}, { suppressRefError: true })}>
                                <div className="phone-search__input">
                                    <input className="vd-input" placeholder="Search..." {...getInputProps({
                                        ref: searchRef,
                                        placeholder: 'Search...',
                                        onKeyDown: event => {
                                            if (event.key === 'Tab') {
                                                if (!highlightedIndex || items[highlightedIndex].isoCode === selectedItem.isoCode) {
                                                    setOptionsVisible(false);
                                                    return;
                                                }

                                                event.preventDefault();
                                                event.nativeEvent.preventDownshiftDefault = true
                                                selectHighlightedItem();
                                            }

                                            if (event.key === 'Escape') {
                                                setOptionsVisible(false);
                                            }
                                        },
                                    })} />
                                </div>

                                {items.length === 0 && (
                                    <div className="no-results">
                                        <strong>
                                            No results...
                                        </strong>
                                    </div>
                                )}

                                <ul className="phone-search__country-list" {...getMenuProps()}>
                                    {items.map((item, index) => {
                                        const selectedClass = selectedItem && (selectedItem.isoCode === item.isoCode) ? 'is-selected' : '';
                                        const highlightedClass = highlightedIndex === index ? 'is-highlighted' : '';

                                        return (
                                            <li
                                                key={`${item.isoCode}_${index}`}
                                                className={`${selectedClass} ${highlightedClass}`}
                                                {...getItemProps({
                                                    index,
                                                    item,
                                                })}
                                            >
                                                <div className={`flag-icon flag-icon-squared flag-icon-${item.isoCode}`} />
                                                <span className="name">{item.name}</span>
                                                <span className="dial-code">{item.dialCode}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                    </Downshift>
                </div>
            </div>
            <input type="text"
                name="phone_validation"
                ref={props.register}
                defaultValue={props.defaultValue}
                style={{ display: debug ? 'block' : 'none' }}
            />
            <input type="text"
                name="phone"
                ref={props.register}
                defaultValue={props.defaultValueWithDialCode}
                style={{ display: debug ? 'block' : 'none' }}
            />
        </div>
    );
};

PhoneInput.defaultProps = {
    onChange: () => { },
    defaultValue: '',
    defaultValueWithDialCode: '',
};

PhoneInput.propTypes = {
    onChange: PropTypes.func,
    register: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    defaultValueWithDialCode: PropTypes.string,
};

export default PhoneInput;
